import React from 'react';

import {
  Checkbox,
  FormControlLabel,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';

import DropDown from 'components/dropDown/DropDown';
import MainBox from 'components/layout/MainBox';
import TitleComponent from 'features/dataviz/components/generic/charts/common/Title';

import { PhysionomyFiltersForm, PhysionomyHistoryFiltersForm } from '../types';

type PhysionomyFiltersProps = {
  values: PhysionomyFiltersForm | PhysionomyHistoryFiltersForm,
  disabled?: boolean,
  onChange: (e: React.ChangeEvent<any>) => void,
  onFieldValueUpdate: (field: 'showPercentage' | 'showValues', value: any) => void,
  onBlur?: (e: React.FocusEvent<any>) => void,
}

function PhysionomyInformations(props: PhysionomyFiltersProps) {
  const {
    values,
    disabled,
    onChange,
    onFieldValueUpdate,
    onBlur,
  } = props;

  const handleDropDownChange = (event: SelectChangeEvent<string>) => {
    const barValues = event.target.value.split(',');

    const showPercentage = barValues[0] === 'true';
    const showValues = barValues[1] === 'true';

    onFieldValueUpdate('showPercentage', showPercentage);
    onFieldValueUpdate('showValues', showValues);
  };

  return (
    <MainBox>
      <TitleComponent
        title="Informations"
        capitalLetter
        direction="column"
      />
      <Stack direction="column" spacing={1}>
        <DropDown
          value={`${values.showPercentage},${values.showValues}`}
          onChange={handleDropDownChange}
          options={[
            { value: 'true,true', label: 'Afficher (en %) les valeurs' },
            { value: 'false,true', label: 'Afficher les valeurs' },
            { value: 'false,false', label: 'Masquer les valeurs' },
          ]}
          size="small"
          fontSize="xsmall"
          disabled={disabled}
          data-testid="select-show-values-drop-down"
        />
        <FormControlLabel
          control={<Checkbox size="small" />}
          label={(
            <Typography variant="body3">
              Masquer les résultats organiques
            </Typography>
          )}
          name="hideOrganic"
          checked={values.hideOrganic}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
          data-testid="physionomy-showValues-formcontrol"
        />
      </Stack>
    </MainBox>
  );
}

export default PhysionomyInformations;
