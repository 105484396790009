import React, { useEffect } from 'react';

import {
  FormControl,
  Typography,
} from '@mui/material';
import { Stack } from '@mui/system';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import {
  FormikErrors,
  FormikHandlers,
} from 'formik';
import moment from 'moment';

import globalTheme from 'common/theme';
import UploadInput from 'components/input/UploadInput';
import ActorsDropDown from 'features/actors/components/ActorsDropDown';
import SelectDataSources from 'features/dataSource/components/SelectDataSource';

import { DATA_SOURCE, UPLOADABLE_DATA_SOURCE, UPLOADABLE_DATA_SOURCE_ID } from '../CONSTANT';
import { DataFileForm } from '../types';

type AddDataFileModalProps = {
  scopeId: number,
  values: DataFileForm,
  handleChange: FormikHandlers['handleChange'],
  setFieldValue: Function,
  errors: FormikErrors<DataFileForm>,
}

function AddDataFileModalForm(props: AddDataFileModalProps) {
  const {
    scopeId,
    values,
    handleChange,
    setFieldValue,
    errors,
  } = props;
  const handleUploadInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files: fileList } = e.currentTarget;
    const files = Array.from(fileList || []);

    const filedValue = (
      files
        .map((file) => file.name)
        .join(', ')
    ) ?? '';

    setFieldValue(
      'files',
      {
        ...values.files,
        file: {
          value: filedValue,
          error: false,
          error_label: '',
          file: files,
        },
      },
    );
  };

  const formatDate = (value: moment.Moment | null) => {
    let formatedValue;
    if (!value || !moment(value).isValid()) {
      formatedValue = null;
    } else if (values.dataSourceId === UPLOADABLE_DATA_SOURCE.screaming_frog.id) {
      formatedValue = value.startOf('day').format();
    } else {
      formatedValue = value.startOf('month').format();
    }

    return formatedValue;
  };

  useEffect(() => {
    if (errors.files?.file?.file) {
      setFieldValue(
        'files',
        {
          ...values.files,
          file: {
            ...values.files.file,
            error: true,
            error_label: errors.files?.file?.file,
          },
        },
      );
    }
  }, [errors]);

  return (
    <Stack
      direction="column"
      alignItems="center"
      width={1}
      padding={1}
      spacing={2}
      data-testid="add-data-files-form"
    >
      <Stack direction="column" alignItems="center">
        <Typography component="span" variant="body3">
          Vous devez sélectionner 1 ou plusieurs fichiers issus du
          {' '}
          <Typography
            component="span"
            variant="bold"
          >
            même outil
            {' '}
          </Typography>
        </Typography>
        <Typography component="span" variant="body3">
          La qualification des acteurs et de la période peut se faire ultérieurement
        </Typography>
      </Stack>
      <FormControl sx={{ m: 1 }} fullWidth>
        <UploadInput
          form={values.files}
          handleChange={handleUploadInputChange}
          accept="text/csv"
          multiple
        />
      </FormControl>
      <SelectDataSources
        value={values.dataSourceId}
        onChange={handleChange}
        filterOptions={UPLOADABLE_DATA_SOURCE_ID}
        error={errors.dataSourceId}
        size="small"
        name="dataSourceId"
        label="Source du fichier"
      />
      <ActorsDropDown
        scopeId={scopeId}
        value={Number(values.actorId) ?? 0}
        onChange={handleChange}
        name="actorId"
        size="small"
        fontSize="medium"
        emptyOption="Acteur"
        label="Acteurs"
        margin={1}
      />
      <LocalizationProvider
        dateAdapter={AdapterMoment}
        adapterLocale={moment.locale('fr')}
      >
        <DatePicker
          label="Période"
          name="periode"
          value={values.periode ? moment(values.periode) : null}
          onChange={
            (value) => setFieldValue(
              'periode',
              formatDate(value),
            )
          }
          format={
            values.dataSourceId === DATA_SOURCE.screaming_frog.id
              ? 'DD/MM/YYYY'
              : 'MM/YYYY'
          }
          views={
            values.dataSourceId === DATA_SOURCE.screaming_frog.id
              ? ['year', 'month', 'day']
              : ['month', 'year']
          }
          slotProps={{
            textField: {
              sx: { marginY: 1, width: 1 },
              size: 'small',
              label: 'Période',
              error: false,
              InputProps: {
                sx: {
                  fontSize: globalTheme.fontSize.medium,
                },
              },
            },
          }}
        />
      </LocalizationProvider>
    </Stack>
  );
}

export default AddDataFileModalForm;
