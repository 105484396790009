import React from 'react';

import { Typography } from '@mui/material';

import globalTheme from 'common/theme';
import TitleComponent from 'features/dataviz/components/generic/charts/common/Title';
import { convertDate, DATE_NO_HOUR } from 'utils/formatDate';

type PhysionomyHistoryTitleProps = {
  periodeBegin?: string,
  periodeEnd?: string,
}

function PhysionomyHistoryTitle(props: PhysionomyHistoryTitleProps) {
  const { periodeBegin, periodeEnd } = props;

  if (!periodeBegin || !periodeEnd) {
    return (
      <TitleComponent
        title={(
          <Typography
            variant="bold"
            data-testid="physionomy-title-text"
          >
            Evolution des résultats
          </Typography>
      )}
        capitalLetter
        justifyContent="center"
      />
    );
  }

  return (
    <TitleComponent
      title={(
        <Typography
          variant="bold"
          data-testid="physionomy-title-text"
        >
          Evolution des résultats -
          <Typography component="span" color={globalTheme.palette.primary.light} variant="bold">
            {' '}
            {convertDate(new Date(periodeBegin), DATE_NO_HOUR)}
          </Typography>
          {' '}
          -
          <Typography component="span" color={globalTheme.palette.primary.light} variant="bold">
            {' '}
            {convertDate(new Date(periodeEnd), DATE_NO_HOUR)}
          </Typography>
        </Typography>
    )}
      capitalLetter
      justifyContent="center"
    />
  );
}

export default PhysionomyHistoryTitle;
