import { DEVICE_TYPE } from 'features/deviceType/CONSTANTS';

import { PhysionomyFiltersForm, PhysionomyHistoryFiltersForm } from '../types';

function initForm(
  actors?: number[],
  serpDevice?: number,
  rankLimit?: number,
  periodeOne?: number,
  periodeTwo?: number,
  showValues?: boolean,
  showPercentage?: boolean,
  hideOrganic?: boolean,
): PhysionomyFiltersForm {
  return {
    actors: actors ?? [],
    deviceId: serpDevice ?? DEVICE_TYPE.DESKTOP_MOBILE,
    rankLimit: rankLimit ?? 20,
    periodeOne,
    periodeTwo,
    showValues: showValues ?? true,
    showPercentage: showPercentage ?? true,
    hideOrganic: hideOrganic ?? false,
  };
}
export function initFormHistory(
  serpDevice?: number,
  rankLimit?: number,
  periode?: string,
  showValues?: boolean,
  showPercentage?: boolean,
  hideOrganic?: boolean,
): PhysionomyHistoryFiltersForm {
  return {
    deviceId: serpDevice ?? DEVICE_TYPE.DESKTOP_MOBILE,
    rankLimit: rankLimit ?? 20,
    periode,
    showValues: showValues ?? true,
    showPercentage: showPercentage ?? true,
    hideOrganic: hideOrganic ?? false,
  };
}

export default initForm;
