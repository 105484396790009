import React from 'react';

import { SelectInputProps } from '@mui/material/Select/SelectInput';

import globalTheme from 'common/theme';
import DropDown from 'components/dropDown/DropDown';
import {
  devicesList,
  devicesListWithoutDevicesCombination,
} from 'features/deviceType/CONSTANTS';

type SearchEngineDropDownProps = {
  value: number,
  onChange: SelectInputProps<any>['onChange'],
  name?: string,
  size?: 'small' | 'medium',
  fontSize?: keyof typeof globalTheme.fontSize,
  label?: string,
  uniqueOptions?: boolean,
}

const options = Object.values(devicesList);
const optionsUnique = Object.values(devicesListWithoutDevicesCombination);

function DeviceDropDown(props: SearchEngineDropDownProps) {
  const {
    value,
    onChange,
    name,
    size,
    fontSize,
    label = 'Appareil',
    uniqueOptions = false,
  } = props;

  return (
    <DropDown
      size={size}
      fontSize={fontSize}
      label={label}
      name={name}
      onChange={onChange}
      options={uniqueOptions ? optionsUnique : options}
      getOptionLabel={(se) => se.label}
      getOptionValue={(se) => se.id}
      value={value}
    />
  );
}

export default DeviceDropDown;
