import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  Box,
  Grid2,
  Typography,
} from '@mui/material';
import { Stack } from '@mui/system';

import { useAppSelector } from 'common/reduxHooks';
import TabsLink from 'components/link/TabsLink';
import useActors from 'features/actors/state/useActors';
import DatavizContainer from 'features/dataviz/components/generic/DatavizContainer';
import { METRIC } from 'features/dataviz/CONSTANTS';
import datavizConfig from 'features/dataviz/datavizConfig';
import { DatavizFilters } from 'features/dataviz/types';
import { Project } from 'features/projects/types';
import { selectAnalysisById } from 'features/serpAnalysis/state/slice';
import useSerpAnalyses from 'features/serpAnalysis/state/useSerpAnalyses';
import PhysionomyFiltersContainer
  from 'features/serpPhysionomy/components/PhysionomyFiltersContainer';
import { PhysionomyFiltersForm } from 'features/serpPhysionomy/types';
import { splitIdName } from 'utils/urlManipulation';

import getPhysionomyNavigation from './physionomyNavigation';
import { SERP_PHYSIONOMY } from '../ROUTES';

type SerpPhysionomyProps = {
  project: Project,
}

function SerpPhysionomy(props: SerpPhysionomyProps) {
  const { project } = props;

  const { idNameAnalysis } = useParams();
  const { id: idAnalysis } = splitIdName(idNameAnalysis);

  const scopeId = project.scopes[0].id;

  const { isLoading } = useSerpAnalyses({ scopeId });
  const { actors } = useActors({ scopeId });
  const serpAnalysis = useAppSelector((state) => (
    selectAnalysisById(state.serpAnalyses, idAnalysis)));

  const [datavizFilters, setDatavizFilters] = useState<DatavizFilters>({
    deviceTypeId: serpAnalysis?.device_type_id,
    rankMax: '50',
    bestRankMode: false,
    rankMin: '1',
    volMax: '',
    volMin: '',
    type: 'datavizFilters',
    actorIds: [],
  });
  const [showPercentage, setShowPercentage] = useState(false);
  const [showValue, setShowValue] = useState(false);
  const [hideOrganic, sethideOrganic] = useState(false);
  const [isInformationsDisabled, setIsInformationsDisabled] = useState(true);

  const handleFiltersSubmit = (filters: PhysionomyFiltersForm) => {
    const scrappingIds: number[] = [];

    if (filters.periodeOne) scrappingIds.push(filters.periodeOne);
    if (filters.periodeTwo) scrappingIds.push(filters.periodeTwo);

    setDatavizFilters((prev) => ({
      ...prev,
      rankMax: filters.rankLimit.toString(),
      deviceTypeId: filters.deviceId,
      serpAnalysisScrappingIds: scrappingIds,
      actorIds: filters.actors,
    }));
    setShowPercentage(filters.showPercentage);
    setShowValue(filters.showValues);
    sethideOrganic(filters.hideOrganic);
  };

  const handleFiltersChanged = (filters: PhysionomyFiltersForm) => {
    setShowPercentage(filters.showPercentage);
    setShowValue(filters.showValues);
    sethideOrganic(filters.hideOrganic);
  };

  const handleDataFetch = () => {
    if (isInformationsDisabled) setIsInformationsDisabled(false);
  };

  return (
    <Box>
      <Typography variant="h1">
        Surveillez votre écosystème, boostez votre visibilité !
      </Typography>
      <Grid2 container columnSpacing={2} size={12}>
        <Grid2 offset={2} size={10}>
          <TabsLink
            links={getPhysionomyNavigation(
              project,
              serpAnalysis,
            )}
            defaultValue={SERP_PHYSIONOMY}
          />
        </Grid2>
        <Grid2 size={2} id="physionomie-filters-container">
          <PhysionomyFiltersContainer
            scopeId={scopeId}
            serpAnalysis={serpAnalysis}
            isLoading={isLoading}
            onSubmit={handleFiltersSubmit}
            onFiltersChanged={handleFiltersChanged}
            disabledInformations={isInformationsDisabled}
          />
        </Grid2>
        <Grid2 size={10}>
          {/* to negate the main box margin */}
          <Stack height="calc(100% - 16px)">
            <DatavizContainer
              datavizComp={datavizConfig[METRIC.count_group_by_ranking_type.label]}
              idScope={scopeId}
              periode=""
              title=""
              datavizFilters={datavizFilters}
              idSerpAnalysis={serpAnalysis?.id}
              actors={actors.filter((a) => datavizFilters.actorIds?.includes(a.id!))}
              containerAspect={1}
              height="100%"
              showPercentage={showPercentage}
              showValue={showValue}
              hideRankingTypeIds={hideOrganic ? [1] : undefined}
              onDataFetch={handleDataFetch}
            />
          </Stack>
        </Grid2>
      </Grid2>
    </Box>
  );
}

export default SerpPhysionomy;
