import React, { ReactNode } from 'react';

import {
  Grid2 as Grid,
  Grid2Props as GridProps,
  SelectChangeEvent,
  Typography,
  styled,
} from '@mui/material';
import moment from 'moment';

import DropDown from 'components/dropDown/DropDown';
import { DefaultDropDownItemsType } from 'components/dropDown/type';
import MainBox from 'components/layout/MainBox';
import ActorsDropDown from 'features/actors/components/ActorsDropDown';
import CATEGORY_TYPE_ID, { UNCATEGORIZED_CATEGORY } from 'features/categoryGroups/CONSTANTS';
import useCategoryGroups from 'features/categoryGroups/state/useCategoryGroups';
import { Category, CategoryGroup } from 'features/categoryGroups/types';
import { DATA_SOURCE } from 'features/dataFiles/CONSTANT';
import TitleComponent from 'features/dataviz/components/generic/charts/common/Title';
import usePeriod from 'features/period/state/usePeriod';
import RankingTypesDropDown from 'features/rankingTypes/components/RankingTypesDropDown';
import RankSliceDropDown from 'features/rankSlice/components/RankSliceDropDown';
import SerpAnalysisDropDown from 'features/serpAnalysis/components/SerpAnalysisDropDown';
import { DATE_ENGLISH, DATE_FULL_NAME_MONTH_YEAR, convertDate } from 'utils/formatDate';

import {
  DEFAULT_PERIODE,
  METRICS,
  RANK_SLICES_LIMITED_20,
  filterStatusPerPage,
} from '../CONSTANT';
import { PerformanceFiltersType, PerformancePages } from '../types';

const PREFIX = 'PerformanceFiltersList';
const classes = {
  select: `${PREFIX}-select`,
  label: `${PREFIX}-label`,
  mainLabel: `${PREFIX}-main-label`,
};

const StyledGrid = styled(Grid)<GridProps>(({ theme }) => ({
  [`& .${classes.select}`]: {
    width: '90%',
    fontSize: theme.fontSize.xsmall,
  },

  [`& .${classes.label}`]: {
    fontSize: theme.fontSize.xsmall,
    marginBottom: '.5rem',
  },

  [`& .${classes.mainLabel}`]: {
    fontSize: theme.fontSize.xsmall,
    marginBottom: '.5rem',
    color: theme.palette.primary.light,
  },
}));

type PerformanceFiltersProps = {
  filters: PerformanceFiltersType,
  scopeId: number,
  page: PerformancePages,
  onChange: (e: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<string>) => void,
  onCategoryGroupChange: Function,
  handleCategoryChange: (event: SelectChangeEvent<any>, child: ReactNode) => void,
};

const metricsDropDownFormated = METRICS.map((metric) => ({ label: metric, value: metric }));
const defaultPeriodFormated = () => ({
  label: convertDate(moment(DEFAULT_PERIODE).toDate(), DATE_FULL_NAME_MONTH_YEAR),
  value: DEFAULT_PERIODE,
});

function PerformanceFilters(props: PerformanceFiltersProps) {
  const {
    filters,
    scopeId,
    page,
    onChange,
    onCategoryGroupChange,
    handleCategoryChange,
  } = props;

  const {
    periods,
    hasPeriods,
    isLoading: isPeriodLoading,
  } = usePeriod(scopeId, [DATA_SOURCE.semrush.id], false);
  const {
    categoryGroups,
    isLoading: isCategoryGroupLoading,
  } = useCategoryGroups(scopeId, CATEGORY_TYPE_ID.url, false);

  let formatedPeriods: DefaultDropDownItemsType[] = [];
  if (isPeriodLoading) formatedPeriods = [];
  else if (hasPeriods) {
    formatedPeriods = periods.map(({ period }) => ({
      label: convertDate(moment(period).utc().toDate(), DATE_FULL_NAME_MONTH_YEAR),
      value: convertDate(moment(period).utc().toDate(), DATE_ENGLISH),
    }));

    if (page === 'kpi') {
      const startOfYear = moment().utc().startOf('year');
      formatedPeriods.push(...[
        {
          label: 'cette année',
          value: convertDate(startOfYear.toDate(), DATE_ENGLISH),
        },
        {
          label: 'l\'année dernière',
          value: convertDate(startOfYear.subtract(1, 'year').toDate(), DATE_ENGLISH),
        },
      ]);
    }
  } else if (!hasPeriods) {
    formatedPeriods = [defaultPeriodFormated()];
  }

  const getDropDownTitle = (filter: PerformancePages) => (filter === page
    ? classes.mainLabel
    : classes.label);

  const getCategoryItems = () => {
    const categoryItems = categoryGroups.find(
      (categoryGroup) => categoryGroup.id === filters.categoryGroupId,
    )?.categories;
    if (
      categoryItems?.length
      && categoryItems?.length > 0
    ) {
      return [...categoryItems]
        .sort((a, b) => a.name.localeCompare(b.name))
        .concat([UNCATEGORIZED_CATEGORY]);
    }
    return [];
  };

  const formatRankingTypeValue = () => {
    if (
      filters.rankingTypeIds.length === 2
      && filters.rankingTypeIds.every((v) => [1, 2].includes(v))
      && page !== 'serp'
    ) {
      return [-1];
    }
    if (page !== 'serp') {
      return [filters.rankingTypeIds[0]];
    }
    return filters.rankingTypeIds;
  };

  const formatCategoryValue = () => {
    if (filters.includeUncategorized && !filters.categoryIds.includes(0)) {
      const newValue = [...filters.categoryIds, 0];
      return newValue;
    }
    return filters.categoryIds;
  };

  return (
    <MainBox>
      <TitleComponent
        title="Affinez vos vues avec les filtres"
        capitalLetter
        direction="column"
      />
      <StyledGrid container direction="row" mt={0} spacing={2} size={12}>
        <Grid
          container
          direction="column"
          size={6}
          spacing={1}
        >
          <Grid container direction="row" size={12}>
            <Grid size={6}>
              <Typography className={getDropDownTitle('period')}>
                {page === 'kpi'
                  ? 'Inclure les données depuis'
                  : 'Sélectionner une période'}
              </Typography>
              <DropDown
                name="period"
                fullWidth
                disabled={isPeriodLoading}
                value={isPeriodLoading ? '' : filters.period}
                options={formatedPeriods}
                label="Mois"
                onChange={onChange}
                size="small"
                fontSize="xsmall"
                className={{ select: classes.select }}
                data-testid="performance-period-dropdown"
              />
            </Grid>
            <Grid size={6}>
              <Typography className={getDropDownTitle('metric')}>
                Sélectionner une métrique
              </Typography>
              <DropDown
                name="metric"
                fullWidth
                value={filters.metric}
                options={metricsDropDownFormated}
                label="Métrique"
                onChange={onChange}
                size="small"
                fontSize="xsmall"
                className={{ select: classes.select }}
                data-testid="performance-metric-dropdown"
                disabled={!filterStatusPerPage[page].metric.enabled}
              />
            </Grid>
          </Grid>
          <Grid container direction="row" size={12}>
            <Grid size={6}>
              <Typography className={getDropDownTitle('actor')}>
                Sélectionner des acteurs
              </Typography>
              <ActorsDropDown
                value={filters.actorIds}
                name="actorIds"
                fontSize="xsmall"
                size="small"
                scopeId={scopeId}
                onChange={onChange}
                multiple
                label="Acteurs"
                className={{ select: classes.select }}
                doFetch={false}
              />
            </Grid>
            <Grid size={6}>
              <Typography className={classes.label}>
                Sélectionner des rangs
              </Typography>
              <RankSliceDropDown
                values={filters.rankSlices}
                onChange={onChange}
                rankSlicesLimited={RANK_SLICES_LIMITED_20}
                className={{ select: classes.select }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container direction="column" size={6} spacing={1}>
          <Grid container direction="row" size={12}>
            <Grid size={6}>
              <Typography className={getDropDownTitle('url')}>
                Sélectionner des URL
              </Typography>
              <DropDown<CategoryGroup>
                name="categoryGroupId"
                fullWidth
                value={filters.categoryGroupId}
                options={categoryGroups}
                getOptionValue={(value) => value.id}
                getOptionLabel={(value) => value.name}
                label="Groupes"
                onChange={(e) => (
                  onCategoryGroupChange(
                    e.target.value,
                    categoryGroups.find(
                      (categoryGroup) => categoryGroup.id === parseInt(e.target.value, 10),
                    )?.categories,
                  )
                )}
                size="small"
                fontSize="xsmall"
                disabled={isCategoryGroupLoading}
                emptyOption="aucun groupe"
                className={{ select: classes.select }}
                data-testid="performance-category-group-dropdown"
              />
            </Grid>
            <Grid size={6}>
              <Typography className={classes.label}>
                &nbsp;
              </Typography>
              <DropDown<Category>
                name="categoryIds"
                fullWidth
                value={formatCategoryValue()}
                options={getCategoryItems()}
                getOptionValue={(value) => value.id}
                getOptionLabel={(value) => value.name}
                label="Sous-Groupes"
                onChange={handleCategoryChange}
                size="small"
                fontSize="xsmall"
                disabled={!filters.categoryGroupId}
                multiple
                className={{ select: classes.select }}
                data-testid="performance-category-dropdown"
              />
            </Grid>
          </Grid>
          <Grid container direction="row" size={12}>
            <Grid size={6}>
              <Typography className={getDropDownTitle('keyword')}>
                Sélectionner des mots clés
              </Typography>
              <SerpAnalysisDropDown
                value={filters.serpAnalysisIds}
                name="serpAnalysisIds"
                fontSize="xsmall"
                size="small"
                scopeId={scopeId}
                onChange={onChange}
                multiple
                label="Catégories"
                className={{ select: classes.select }}
                optionEmptyHelper="Aucune catégorie"
                doFetch={false}
              />
            </Grid>
            <Grid size={6}>
              <Typography className={getDropDownTitle('serp')}>
                Sélectionner les fonctionnalités
              </Typography>
              <RankingTypesDropDown
                value={formatRankingTypeValue()}
                name="rankingTypeIds"
                size="small"
                onChange={onChange}
                label="Encarts SERP"
                className={{ select: classes.select }}
                fontSize="xsmall"
                multiple={page === 'serp'}
              />
            </Grid>
          </Grid>
        </Grid>
      </StyledGrid>
    </MainBox>
  );
}

export default PerformanceFilters;
