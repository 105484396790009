import React from 'react';

import { Typography } from '@mui/material';
import moment from 'moment';

import globalTheme from 'common/theme';
import TitleComponent from 'features/dataviz/components/generic/charts/common/Title';
import { convertDate, DATE_NUMBERS_HOURS } from 'utils/formatDate';

type PhysionomyTitleProps = {
  scrappingOne?: string,
  scrappingOneDevice?: string,
  scrappingTwo?: string,
  scrappingTwoDevice?: string,
}

function PhysionomyTitle(props: PhysionomyTitleProps) {
  const {
    scrappingOne,
    scrappingOneDevice,
    scrappingTwo,
    scrappingTwoDevice,
  } = props;
  const baseTitle = 'PHYSIONOMIE DES RÉSULTATS - ';

  if (!scrappingOne && !scrappingTwo) {
    return (
      <TitleComponent
        title={(
          <Typography
            color={globalTheme.palette.primary.light}
            variant="bold"
            data-testid="physionomy-title-text"
          >
            Utilisez les filtres ci-contre pour afficher vos données
          </Typography>
        )}
        capitalLetter
        justifyContent="center"
      />
    );
  }

  if (scrappingOne && scrappingTwo) {
    if (scrappingOne === scrappingTwo) {
      return (
        <TitleComponent
          title={(
            <Typography variant="bold" data-testid="physionomy-title-text">
              {baseTitle}
              <Typography component="span" color={globalTheme.palette.primary.light} variant="bold">
                {convertDate(
                  moment(scrappingOne).toDate(),
                  DATE_NUMBERS_HOURS,
                )}
                {' '}
                {scrappingOneDevice}
              </Typography>
              <Typography component="span" variant="bold">
                {' '}
                VS
                {' '}
              </Typography>
              <Typography component="span" color={globalTheme.palette.primary.light} variant="bold">
                {scrappingTwoDevice}
              </Typography>
            </Typography>
          )}
          capitalLetter
          justifyContent="center"
        />
      );
    }

    return (
      <TitleComponent
        title={(
          <Typography variant="bold" data-testid="physionomy-title-text">
            {baseTitle}
            <Typography component="span" color={globalTheme.palette.primary.light} variant="bold">
              {`${convertDate(
                moment(scrappingOne).toDate(),
                DATE_NUMBERS_HOURS,
              )} ${scrappingOneDevice ?? ''}`.trim()}
            </Typography>
            <Typography component="span" variant="bold">
              {' '}
              VS
              {' '}
            </Typography>
            <Typography component="span" color={globalTheme.palette.primary.light} variant="bold">
              {`${convertDate(
                moment(scrappingTwo).toDate(),
                DATE_NUMBERS_HOURS,
              )} ${scrappingTwoDevice ?? ''}`.trim()}
            </Typography>
          </Typography>
        )}
        capitalLetter
        justifyContent="center"
      />
    );
  }

  return (
    <TitleComponent
      title={(
        <Typography variant="bold" data-testid="physionomy-title-text">
          {baseTitle}
          <Typography component="span" color={globalTheme.palette.primary.light} variant="bold">
            {`${convertDate(
              moment(scrappingOne ?? scrappingTwo).toDate(),
              DATE_NUMBERS_HOURS,
            )} ${scrappingOneDevice ?? scrappingTwoDevice ?? ''}`.trim()}
          </Typography>
        </Typography>
      )}
      capitalLetter
      justifyContent="center"
    />
  );
}

export default PhysionomyTitle;
