import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Analysis } from 'features/serpAnalysis/types';
import serializeSearchParams from 'utils/querySerializer';
import {
  deleteParamsFromQuery,
  isOneOrMoreFiltersInQuery,
} from 'utils/urlManipulation';

import {
  DEFAULT_RANK_MAX,
  DEFAULT_RANK_MIN,
  DEFAULT_SA_ID,
  DEFAULT_SITE_COUNT,
  DEFAULT_VOL_MIN,
  SITECOUNT_LIST,
  SI_ALL,
  SI_NC,
} from './CONSTANT';
import ParamsBlockView from './ParamsBlockView';
import { SAFilters } from './types';

interface ParamsBlockProps {
  blockHeight: number,
  analysis: Analysis,
}

function ParamsBlock (props: ParamsBlockProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  const { blockHeight, analysis } = props;

  let maxVolAnalysis = analysis.max_volume;
  const maxVol = maxVolAnalysis;
  const intentList = analysis.sa_keyword_intentions;
  const siAllList = (intentList && intentList.length > 0)
    ? [
      SI_ALL,
      ...intentList,
      SI_NC,
    ]
    : [SI_ALL];

  const searchIntentId = parseInt(searchParams.get('searchIntentId') ?? `${DEFAULT_SA_ID}`, 10);
  let sitesCount = parseInt(searchParams.get('sitesCount') ?? `${DEFAULT_SITE_COUNT}`, 10);
  if (!SITECOUNT_LIST.find((si) => si === sitesCount)) {
    sitesCount = DEFAULT_SITE_COUNT;
  }
  const rankMin = parseInt(searchParams.get('rankMin') ?? `${DEFAULT_RANK_MIN}`, 10);
  const rankMax = parseInt(searchParams.get('rankMax') ?? `${DEFAULT_RANK_MAX}`, 10);
  const volMin = parseInt(searchParams.get('volMin') ?? `${DEFAULT_VOL_MIN}`, 10);
  const volMax = parseInt(searchParams.get('volMax') ?? `${maxVol}`, 10);
  const bestRankMode = searchParams.get('bestRankMode') === 'true';

  const queryParams = [
    'rankMin',
    'rankMax',
    'volMin',
    'volMax',
    'sitesCount',
    'searchIntentId',
    'bestRankMode',
  ];

  const [filters, setFilters] = useState<SAFilters>({
    searchIntentId,
    sitesCount,
    rankMin,
    rankMax,
    volMin,
    volMax,
    bestRankMode,
  });
  const handleChange = (name: string, value: number) => {
    setFilters({ ...filters, [name]: value });
  };

  const handleRangeChange = (rangeName: string, status: 'Min' | 'Max', value: number) => {
    const newValue: { [filterName: string]: number } = { [rangeName + status]: value };
    setFilters({ ...filters, ...newValue });
  };

  const handleSubmit = (e: any, values: SAFilters) => {
    e.preventDefault();
    setSearchParams(serializeSearchParams({
      sitesCount: values.sitesCount,
      searchIntentId: values.searchIntentId,
      rankMin: values.rankMin,
      rankMax: values.rankMax,
      volMin: values.volMin,
      volMax: values.volMax,
      bestRankMode: values.bestRankMode,
      idScrapping: searchParams.get('idScrapping') ?? undefined,
      deviceType: searchParams.get('deviceType') ?? undefined,
    }));
  };

  const resetQuery = () => {
    const queryWithParamsDeleted = deleteParamsFromQuery(queryParams, searchParams);
    setSearchParams(queryWithParamsDeleted);
  };

  useEffect(() => {
    setFilters({
      searchIntentId,
      sitesCount,
      rankMin,
      rankMax,
      volMin,
      volMax,
      bestRankMode,
    });
  }, [searchParams]);

  useEffect(() => {
    maxVolAnalysis = analysis.max_volume;
  }, [analysis]);

  return (
    <ParamsBlockView
      blockHeight={blockHeight}
      filters={filters}
      handleChange={handleChange}
      handleRangeChange={handleRangeChange}
      handleSubmit={handleSubmit}
      maxVol={maxVol}
      intentList={siAllList}
      siteCountList={SITECOUNT_LIST}
      resetQuery={resetQuery}
      isFilter={isOneOrMoreFiltersInQuery(queryParams, searchParams)}
    />
  );
}

export default ParamsBlock;
