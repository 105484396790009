import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { Grid2, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import moment from 'moment';

import { useAppSelector } from 'common/reduxHooks';
import TabsLink from 'components/link/TabsLink';
import DatavizContainer from 'features/dataviz/components/generic/DatavizContainer';
import { METRIC } from 'features/dataviz/CONSTANTS';
import datavizConfig from 'features/dataviz/datavizConfig';
import { DatavizFilters } from 'features/dataviz/types';
import { Project } from 'features/projects/types';
import useRankingTypes from 'features/rankingTypes/state/useRankingTypes';
import { selectAnalysisById } from 'features/serpAnalysis/state/slice';
import useSerpAnalyses from 'features/serpAnalysis/state/useSerpAnalyses';
import PhysionomyHistoryFiltersContainer
  from 'features/serpPhysionomy/components/PhysionomyHistoryFiltersContainer';
import PhysionomyHistoryTitle from 'features/serpPhysionomy/components/PhysionomyHistoryTitle';
import { PhysionomyHistoryFiltersForm } from 'features/serpPhysionomy/types';
import { convertDate, DATE_NUMBERS_HOURS_SC } from 'utils/formatDate';
import { splitIdName } from 'utils/urlManipulation';

import getPhysionomyNavigation from './physionomyNavigation';
import { SERP_PHYSIONOMY_HISTORY } from '../ROUTES';

type SerpPhysionomyHistoryProps = {
  project: Project,
}

function SerpPhysionomyHistory(props: SerpPhysionomyHistoryProps) {
  const { project } = props;

  const { idNameAnalysis } = useParams();
  const { id: idAnalysis } = splitIdName(idNameAnalysis);

  const scopeId = project.scopes[0].id;

  const { isLoading } = useSerpAnalyses({ scopeId });
  useRankingTypes();

  const serpAnalysis = useAppSelector((state) => (
    selectAnalysisById(state.serpAnalyses, idAnalysis)));

  const [datavizFilters, setDatavizFilters] = useState<DatavizFilters>({
    deviceTypeId: serpAnalysis?.device_type_id ?? 1,
    bestRankMode: false,
    rankMin: '1',
    rankMax: '20',
    periodeBegin: undefined,
    periodeEnd: undefined,
    timeSeries: 'day',
    type: 'datavizFilters',
    volMax: '',
    volMin: '',
  });

  const [showPercentage, setShowPercentage] = useState(false);
  const [showValue, setShowValue] = useState(false);
  const [hideOrganic, sethideOrganic] = useState(false);
  const [isInformationsDisabled, setIsInformationsDisabled] = useState(true);

  const handleFiltersSubmit = (filters: PhysionomyHistoryFiltersForm) => {
    const scrappingIds: number[] = [];

    setDatavizFilters((prev) => ({
      ...prev,
      rankMax: filters.rankLimit.toString(),
      deviceTypeId: filters.deviceId,
      serpAnalysisScrappingIds: scrappingIds,
      periodeBegin: convertDate(
        moment(filters.periode).startOf('month').toDate(),
        DATE_NUMBERS_HOURS_SC,
      ),
      periodeEnd: convertDate(
        moment(filters.periode).endOf('month').toDate(),
        DATE_NUMBERS_HOURS_SC,
      ),
    }));
    setShowPercentage(filters.showPercentage);
    setShowValue(filters.showValues);
    sethideOrganic(filters.hideOrganic);
  };

  const handleFiltersChanged = (filters: PhysionomyHistoryFiltersForm) => {
    setShowPercentage(filters.showPercentage);
    setShowValue(filters.showValues);
    sethideOrganic(filters.hideOrganic);
  };

  const handleDataFetch = () => {
    if (isInformationsDisabled) setIsInformationsDisabled(false);
  };

  return (
    <Box>
      <Typography variant="h1">
        Surveillez votre écosystème, boostez votre visibilité !
      </Typography>
      <Grid2 container columnSpacing={2} size={12}>
        <Grid2 offset={2} size={10}>
          <TabsLink
            links={getPhysionomyNavigation(
              project,
              serpAnalysis,
            )}
            defaultValue={SERP_PHYSIONOMY_HISTORY}
          />
        </Grid2>
        <Grid2 size={2} id="physionomie-filters-container">
          <PhysionomyHistoryFiltersContainer
            serpAnalysis={serpAnalysis}
            isLoading={isLoading}
            onSubmit={handleFiltersSubmit}
            onFiltersChanged={handleFiltersChanged}
            disabledInformations={isInformationsDisabled}
          />
        </Grid2>
        <Grid2 size={10}>
          {/* to negate the main box margin */}
          <Stack height="calc(100% - 16px)">
            <DatavizContainer
              datavizComp={datavizConfig[METRIC.count_group_by_ranking_type_and_periods.label]}
              idScope={scopeId}
              periode=""
              title={(
                <PhysionomyHistoryTitle
                  periodeBegin={datavizFilters.periodeBegin}
                  periodeEnd={datavizFilters.periodeEnd}
                />
              )}
              datavizFilters={datavizFilters}
              idSerpAnalysis={serpAnalysis?.id}
              containerAspect={1}
              height="600px"
              showPercentage={showPercentage}
              showValue={showValue}
              hideRankingTypeIds={hideOrganic ? [1] : undefined}
              onDataFetch={handleDataFetch}
            />
          </Stack>
        </Grid2>
      </Grid2>
    </Box>
  );
}

export default SerpPhysionomyHistory;
