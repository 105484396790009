import CountWithScraps from './components/keyword/CountWithScraps';
import CountWithFilters from './components/rank_tracking/CountWithFilters';
import DetailedRankTracking from './components/rank_tracking/DetailedRankTracking';
import DatasByRankingType from './components/ranking/DatasByRankingType';
import GroupByDomain from './components/ranking/GroupByDomain';
import GroupByRankingType from './components/ranking/GroupByRankingType';
import GroupByRankingTypeAndPeriods from './components/ranking/GroupByRankingTypeAndPeriods';
import GroupResultsByRankingType from './components/ranking/GroupResultsByRankingType';
import PerformanceByActors from './components/seoPerformance/byActor/PerformanceByActors';
import PerformanceByRankingTypes
  from './components/seoPerformance/byRankingTypes/PerformanceByRankingTypes';
import PerformanceByUrls from './components/seoPerformance/byUrls/PerformanceByUrls';
import UrlsByActor from './components/url/urlByActor/UrlsByActor';
import { RESSOURCE, METRIC } from './CONSTANTS';
import { DatavizConfigsType } from './types';

const datavizConfig: DatavizConfigsType = {
  [METRIC.count_by_ranking_type_group_by_domain.label]: {
    label: METRIC.count_by_ranking_type_group_by_domain.label,
    element: GroupByDomain,
    metrics: [
      {
        resource: RESSOURCE.ranking,
        metric: METRIC.count_by_ranking_type_group_by_domain.key,
      },
    ],
  },
  [METRIC.get_detailed_datas_by_ranking_type.label]: {
    label: METRIC.get_detailed_datas_by_ranking_type.label,
    element: DatasByRankingType,
    metrics: [
      {
        resource: RESSOURCE.ranking,
        metric: METRIC.get_detailed_datas_by_ranking_type.key,
      },
    ],
  },
  [METRIC.count_group_by_ranking_type.label]: {
    label: METRIC.count_group_by_ranking_type.label,
    element: GroupByRankingType,
    metrics: [
      {
        resource: RESSOURCE.ranking,
        metric: METRIC.count_group_by_ranking_type.key,
      },
    ],
  },
  [METRIC.count_group_by_ranking_type_condensed.label]: {
    label: METRIC.count_group_by_ranking_type_condensed.label,
    element: GroupResultsByRankingType,
    metrics: [
      {
        resource: RESSOURCE.ranking,
        metric: METRIC.count_group_by_ranking_type_condensed.key,
      },
    ],
  },
  [METRIC.count_group_by_ranking_type_and_periods.label]: {
    label: METRIC.count_group_by_ranking_type_and_periods.label,
    element: GroupByRankingTypeAndPeriods,
    metrics: [
      {
        resource: RESSOURCE.ranking,
        metric: METRIC.count_group_by_ranking_type_and_periods.key,
      },
    ],
  },

  [METRIC.sum_volume_by_ranking_type_group_by_domain.label]: {
    label: METRIC.sum_volume_by_ranking_type_group_by_domain.label,
    element: GroupByDomain,
    metrics: [
      {
        resource: RESSOURCE.keyword,
        metric: METRIC.sum_volume_by_ranking_type_group_by_domain.key,
      },
    ],
  },
  [METRIC.count_with_scrapped.label]: {
    label: METRIC.count_with_scrapped.label,
    element: CountWithScraps,
    metrics: [
      {
        resource: RESSOURCE.keyword,
        metric: METRIC.count_with_scrapped.key,
      },
    ],
  },
  [METRIC.list_keyword_with_rank_and_url.label]: {
    label: METRIC.list_keyword_with_rank_and_url.label,
    element: DatasByRankingType,
    metrics: [
      {
        resource: RESSOURCE.keyword,
        metric: METRIC.list_keyword_with_rank_and_url.key,
      },
    ],
  },

  [METRIC.count_with_filters_group_by_actors_rank.label]: {
    label: METRIC.count_with_filters_group_by_actors_rank.label,
    element: CountWithFilters,
    metrics: [
      {
        resource: RESSOURCE.rank_tracking,
        metric: METRIC.count_with_filters_group_by_actors_rank.key,
      },
      {
        resource: RESSOURCE.keyword,
        metric: METRIC.count_by_period_and_serp_analysis_scrapping.key,
      },
    ],
  },
  [METRIC.detailed_datas_with_sa_filters.label]: {
    label: METRIC.detailed_datas_with_sa_filters.label,
    element: DetailedRankTracking,
    metrics: [
      {
        resource: RESSOURCE.rank_tracking,
        metric: METRIC.detailed_datas_with_sa_filters.key,
      },
    ],
  },

  [METRIC.list_all.label]: {
    label: METRIC.list_all.label,
    element: undefined,
    metrics: [
      {
        resource: RESSOURCE.serp_analysis,
        metric: METRIC.list_all.key,
      },
    ],
  },
  [METRIC.get_all_by_actors.label]: {
    label: METRIC.get_all_by_actors.label,
    exportLabel: METRIC.get_performance_export.label,
    element: PerformanceByActors,
    metrics: [
      {
        resource: RESSOURCE.seo_performance,
        metric: METRIC.get_all_by_actors.key,
      },
    ],
    export: {
      resource: RESSOURCE.seo_performance,
      metric: METRIC.get_performance_export.key,
    },
  },
  [METRIC.get_all_by_url_categories.label]: {
    label: METRIC.get_all_by_url_categories.label,
    exportLabel: METRIC.get_performance_export.label,
    element: PerformanceByUrls,
    metrics: [
      {
        resource: RESSOURCE.seo_performance,
        metric: METRIC.get_all_by_url_categories.key,
      },
    ],
    export: {
      resource: RESSOURCE.seo_performance,
      metric: METRIC.get_performance_export.key,
    },
  },
  [METRIC.get_all_by_ranking_type.label]: {
    label: METRIC.get_all_by_ranking_type.label,
    exportLabel: METRIC.get_performance_export.label,
    element: PerformanceByRankingTypes,
    metrics: [
      {
        resource: RESSOURCE.seo_performance,
        metric: METRIC.get_all_by_ranking_type.key,
      },
    ],
    export: {
      resource: RESSOURCE.seo_performance,
      metric: METRIC.get_performance_export.key,
    },
  },
  [METRIC.get_all_search_volumes.label]: {
    label: METRIC.get_all_search_volumes.label,
    exportLabel: METRIC.get_all_search_volumes.label,
    element: undefined,
    metrics: [],
    export: {
      resource: RESSOURCE.keyword_serp_detail,
      metric: METRIC.get_all_search_volumes.key,
    },
  },
  [METRIC.get_all_urls_by_actors.label]: {
    label: METRIC.get_all_urls_by_actors.label,
    exportLabel: METRIC.get_all_urls_by_actors.label,
    element: UrlsByActor,
    metrics: [
      {
        resource: RESSOURCE.url,
        metric: METRIC.get_all_urls_by_actors.key,
      },
    ],
  },
};

export default datavizConfig;
