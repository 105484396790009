import React, { useMemo } from 'react';

import {
  CircularProgress,
} from '@mui/material';

import MainBox from 'components/layout/MainBox';
import { ChartComponentProps } from 'features/dataviz/types';
import { formatByRankingTypeAndPeriodsDatas } from 'features/dataviz/utils/formatData';
import useRankingTypes from 'features/rankingTypes/state/useRankingTypes';

import BarChartComponent from '../generic/charts/BarChart';

function GroupByRankingTypeAndPeriods(props: ChartComponentProps) {
  const {
    datas,
    isPageLoading,
    datavizFilters,
    showPercentage,
    showValue,
    hideRankingTypeIds,
    isLoading,
    title,
  } = props;
  const filters = datavizFilters?.type === 'datavizFilters' ? datavizFilters : undefined;
  const { rankingTypes } = useRankingTypes();

  const dataKeys = rankingTypes.map((rt) => ({
    dataKey: rt.name,
    color: undefined,
    stackId: 1,
  }));

  const formatedDatas = useMemo(
    () => formatByRankingTypeAndPeriodsDatas(datas.data.values, dataKeys, hideRankingTypeIds),
    [datas.data.values, hideRankingTypeIds],
  );

  if (isLoading) {
    return (
      <MainBox BoxProps={{ textAlign: 'center', alignContent: 'center', height: '100%' }}>
        <CircularProgress data-testid="physionomy-group-by-ranking-type-periods-spinner" />
      </MainBox>
    );
  }

  if (!filters || !datas.data.values || !datas.data.values.length) {
    return (
      <MainBox BoxProps={{ textAlign: 'center', alignContent: 'center', height: '100%' }}>
        <div>
          vide
        </div>
      </MainBox>
    );
  }

  return (
    <MainBox
      data-testid="group-result-main-box"
    >
      <BarChartComponent
        xAxisDatakey="periode"
        dataKeys={dataKeys}
        datas={formatedDatas}
        isPageLoading={isPageLoading}
        title={title}
        centerLabelName={showValue}
        percentLabel={showPercentage}
        withTooltip
        size="mediumbig"
      />
    </MainBox>
  );
}

export default GroupByRankingTypeAndPeriods;
