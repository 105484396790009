import React from 'react';

import { CircularProgress, Divider, Stack } from '@mui/material';

import MainBox from 'components/layout/MainBox';
import { ChartComponentProps } from 'features/dataviz/types';
import useSerpAnalysisScrappings
  from 'features/serpAnalysisScrapping/state/useSerpAnalysisScrappings';
import PhysionomyTitle from 'features/serpPhysionomy/components/PhysionomyTitle';

import GroupResultsByRankingTypeGraph from './GroupResultsByRankingTypeGraph';

function GroupByRankingType(props: ChartComponentProps) {
  const {
    datas,
    isPageLoading,
    actors,
    datavizFilters,
    analysis,
    showPercentage,
    showValue,
    hideRankingTypeIds,
    isLoading,
  } = props;
  const filters = datavizFilters?.type === 'datavizFilters' ? datavizFilters : undefined;

  const { scrappings } = useSerpAnalysisScrappings(analysis?.id ?? 0);

  const sortedScrapping = filters?.serpAnalysisScrappingIds?.sort();
  const scrappingToCompareOne = scrappings.find(
    (scrap) => sortedScrapping?.[0] === scrap.id,
  );
  const scrappingToCompareTwo = scrappings.find(
    (scrap) => sortedScrapping?.[1] === scrap.id,
  );

  const scrappingIds = filters?.serpAnalysisScrappingIds;

  if (isLoading) {
    return (
      <MainBox BoxProps={{ textAlign: 'center', alignContent: 'center', height: '100%' }}>
        <CircularProgress data-testid="physionomy-group-by-ranking-type-spinner" />
      </MainBox>
    );
  }

  if (!filters || !scrappingIds || !actors || !datas.data.values) {
    return (
      <MainBox BoxProps={{ textAlign: 'center', alignContent: 'center', height: '100%' }}>
        <div>
          vide
        </div>
      </MainBox>
    );
  }

  const dataSetOne = datas.data.values.filter(
    (data) => data.scrapping_id === scrappingToCompareOne?.id,
  );
  const dataSetTwo = datas.data.values.filter(
    (data) => data.scrapping_id === scrappingToCompareTwo?.id,
  );

  // List all ranking types to complete missing ones
  const rankingTypes: string[] = [
    ...new Set(datas.data.values
      .filter((rt) => (hideRankingTypeIds
        ? !hideRankingTypeIds.includes(rt.ranking_type_id)
        : true
      ))
      .map((data) => data.ranking_type_name.replace('_', ' '))),
  ].sort((a, b) => a.localeCompare(b));
  // List all actors to complete missing ones
  const actorNames: string[] = actors.map((actor) => actor.name);

  return (
    <MainBox
      BoxProps={{ justifyContent: 'center', height: '100%' }}
      data-testid="group-result-main-box"
    >
      <Stack direction="column" gap={3} mb="16px" height="100%">
        <PhysionomyTitle
          scrappingOne={scrappingToCompareOne?.scrapping_date}
          scrappingOneDevice={scrappingToCompareOne?.device_type.name}
          scrappingTwo={scrappingToCompareTwo?.scrapping_date}
          scrappingTwoDevice={scrappingToCompareTwo?.device_type.name}
        />
        <Stack direction="row" width="100%" justifyContent="space-between" height="100%">
          <GroupResultsByRankingTypeGraph
            actors={actors}
            datas={dataSetOne}
            isPageLoading={isPageLoading}
            actorNames={actorNames}
            rankingTypes={rankingTypes}
            showValue={showValue}
            showPercentage={showPercentage}
          />
          <Divider
            orientation="vertical"
            flexItem
          />
          <GroupResultsByRankingTypeGraph
            actors={actors}
            datas={dataSetTwo}
            isPageLoading={isPageLoading}
            actorNames={actorNames}
            rankingTypes={rankingTypes}
            showValue={showValue}
            showPercentage={showPercentage}
          />
        </Stack>
      </Stack>
    </MainBox>
  );
}

export default GroupByRankingType;
