import {
  GRAPH_TYPES,
  METRICS,
  NOTORIETY,
  NotorietyType,
} from 'features/performance/CONSTANT';
import { RankLabel } from 'features/rankSlice/types';

import {
  ActorsSliceList,
  ActorsSliceListPercentage,
  FormatedActorsData,
  FormatedActorsLegend,
  FormatedActorsNotoriety,
  FormatedLegendType,
  FormatedUrlsData,
  FormatedUrlsDataMultiple,
  FormatedUrlsNotoriety,
  GetAllActorsPositionningType,
  GetAllUrlsPositionningType,
  GetAllRankingTypesPositionningType,
  FormatedRankingTypesNotoriety,
  FormatedRankingTypeData,
  FormatedRankingTypesDataMultiple,
} from './types';

export default function formatCountWithFiltersData (data: any[], datavizMode: string) {
  const result = data.reduce((acc, cur) => [
    ...acc.filter((obj: any) => obj.periode !== cur.periode),
    {
      ...(
        acc.find((obj: any) => obj.periode === cur.periode)
      ?? {}
      ),
      periode: cur.periode,
      [cur.name]: datavizMode === 'rank'
        ? cur.value_rank
        : cur.value_vol,
    },
  ], []);
  return result;
}

export function formatByActorsData(
  data: GetAllActorsPositionningType[],
  datavizMode: string,
  graphType: keyof typeof GRAPH_TYPES,
  rankList: RankLabel[] = [],
): FormatedActorsData[] {
  const results = data.filter(
    (obj) => {
      if (graphType === GRAPH_TYPES.rank_brand.label) return obj.category_brand === NOTORIETY[0];
      if (
        graphType === GRAPH_TYPES.rank_not_brand.label
      ) return obj.category_brand === NOTORIETY[1];
      return true;
    },
  ).reduce((acc: FormatedActorsData[], cur) => {
    const myActor = acc.find((obj: any) => (
      obj.actor_name === cur.actor_name
    ));

    let newList: FormatedActorsData[] = [];

    let valueCur = 0;
    if (datavizMode === METRICS[0]) {
      valueCur = cur.rank;
    } else if (datavizMode === METRICS[1]) {
      valueCur = cur.search_volume;
    } else if (datavizMode === METRICS[2]) {
      valueCur = cur.traffic;
    }

    if (myActor !== undefined) {
      const newRankSliceValue = (
        (cur.rank_slice in myActor ? myActor[cur.rank_slice]! : 0)
      ) + valueCur;

      const totalValue = Object.keys(myActor)
        .filter((key) => rankList.includes(key as RankLabel))
        .map((key) => myActor[key as RankLabel]!)
        .reduce((total, value) => total + value, 0)
        + (rankList.includes(cur.rank_slice) ? valueCur : 0);

      newList = [
        ...acc.filter((obj: any) => (
          obj.actor_name !== cur.actor_name
        )),
        {
          ...myActor,
          [cur.rank_slice]: newRankSliceValue,
          total: totalValue,
        },
      ];
    } else {
      newList = [...acc];
      const emptyRanks: {
        [key: string]: number
      } = {};
      rankList.forEach((rank) => {
        emptyRanks[rank as string] = 0;
      });
      newList.push({
        ...emptyRanks,
        actor_name: cur.actor_name,
        [cur.rank_slice]: valueCur,
        total: rankList.includes(cur.rank_slice) ? valueCur : 0,
      });
    }
    return newList;
  }, []);

  results.forEach((result) => {
    let totalPercentage = 0;

    rankList
      .forEach((rank) => {
        const percentageValue = Math.round((result[rank]! / result.total) * 100);
        result[`${rank}-percentage`] = percentageValue;
        totalPercentage += percentageValue;
      });

    if (totalPercentage !== 100) {
      const rankToUpdate = rankList.find((rank) => result[`${rank}-percentage`]);
      if (rankToUpdate) result[`${rankToUpdate}-percentage`]! += (100 - totalPercentage);
    }
  });

  return results;
}

export function formatByActorsLegend (
  data: GetAllActorsPositionningType[],
  graphType: keyof typeof GRAPH_TYPES,
): FormatedActorsLegend[] {
  const result = data.filter(
    (obj) => {
      if (graphType === GRAPH_TYPES.rank_brand.label) return obj.category_brand === 'Marque';
      if (
        graphType === GRAPH_TYPES.rank_not_brand.label
      ) return obj.category_brand === 'Hors-Marque';
      return true;
    },
  ).reduce((previous: FormatedActorsLegend[], value) => {
    const actor = previous.find((prevValue) => prevValue.actor_name === value.actor_name);
    if (actor) {
      const newActor = {
        ...actor,
        rank: actor.rank + value.rank,
        search_volume: actor.search_volume + value.search_volume,
        traffic: actor.traffic + value.traffic,
      };
      previous[previous.indexOf(actor)] = newActor;
    } else {
      previous.push(value);
    }
    return previous;
  }, []);
  return result as any[];
}

export function formatByActorsNotoriety (
  data: GetAllActorsPositionningType[],
  datavizMode: string,
) {
  const results = data.reduce((acc: FormatedActorsNotoriety[], cur) => {
    const myActor = acc.find((obj: any) => (
      obj.actor_name === cur.actor_name
    ));

    let newList = [];

    let valueCur = 0;
    if (datavizMode === METRICS[0]) {
      valueCur = cur.rank;
    } else if (datavizMode === METRICS[1]) {
      valueCur = cur.search_volume;
    } else if (datavizMode === METRICS[2]) {
      valueCur = cur.traffic;
    }

    if (myActor !== undefined) {
      const totalValue = Object.keys(myActor)
        .filter((key) => NOTORIETY.includes(key as any))
        .map((key) => myActor[key as NotorietyType]!)
        .reduce((total: number, value: number) => total + value, 0) + valueCur;

      newList = [
        ...acc.filter((obj: any) => (
          obj.actor_name !== cur.actor_name
        )),
        {
          ...myActor,
          [cur.category_brand]: (myActor[cur.category_brand] ?? 0) + valueCur,
          total: totalValue,
        },
      ];
    } else {
      newList = [...acc];
      newList.push({
        actor_name: cur.actor_name,
        [cur.category_brand]: valueCur,
        total: valueCur,
      });
    }
    return newList;
  }, []);

  results.forEach((result: any) => {
    let totalPercentage = 0;

    NOTORIETY
      .forEach((notoriety) => {
        const percentageValue = Math.round((result[notoriety] / result.total) * 100);
        result[`${notoriety}-percentage`] = percentageValue;
        totalPercentage += percentageValue;
      });

    if (totalPercentage !== 100) {
      const notorietyToUpdate = NOTORIETY.find(
        (notoriety) => result[`${notoriety}-percentage`],
      );
      if (notorietyToUpdate) result[`${notorietyToUpdate}-percentage`] += (100 - totalPercentage);
    }
  });

  return results;
}

export function formatByUrlsData(
  data: GetAllUrlsPositionningType[],
  datavizMode: string,
  graphType: keyof typeof GRAPH_TYPES,
  rankList: RankLabel[] = [],
): FormatedUrlsData[] {
  const results = data.filter(
    (obj) => {
      if (graphType === GRAPH_TYPES.rank_brand.label) return obj.category_brand === NOTORIETY[0];
      if (
        graphType === GRAPH_TYPES.rank_not_brand.label
      ) return obj.category_brand === NOTORIETY[1];
      return true;
    },
  ).reduce((acc: FormatedUrlsData[], cur) => {
    const myCategory = acc.find((obj) => (
      obj.category_name === (cur.category_name ?? 'Non catégorisée')
    ));

    let newList: FormatedUrlsData[] = [];

    let valueCur = 0;
    if (datavizMode === METRICS[0]) {
      valueCur = cur.rank;
    } else if (datavizMode === METRICS[1]) {
      valueCur = cur.search_volume;
    } else if (datavizMode === METRICS[2]) {
      valueCur = cur.traffic;
    }

    if (myCategory !== undefined) {
      const newRankSliceValue = (
        (cur.rank_slice in myCategory ? myCategory[cur.rank_slice]! : 0)
      ) + valueCur;

      const totalValue = Object.keys(myCategory)
        .filter((key) => rankList.includes(key as RankLabel))
        .map((key) => myCategory[key as RankLabel]!)
        .reduce((total, value) => total + value, 0)
        + (rankList.includes(cur.rank_slice) ? valueCur : 0);

      newList = [
        ...acc.filter((obj: any) => (
          obj.category_name !== (cur.category_name ?? 'Non catégorisée')
        )),
        {
          ...myCategory,
          [cur.rank_slice]: newRankSliceValue,
          total: totalValue,
        },
      ];
    } else {
      newList = [...acc];
      const emptyRanks: {
        [key: string]: number
      } = {};
      rankList.forEach((rank) => {
        emptyRanks[rank as string] = 0;
      });
      newList.push({
        ...emptyRanks,
        actor_name: cur.actor_name,
        category_name: cur.category_name ?? 'Non catégorisée',
        [cur.rank_slice]: valueCur,
        total: rankList.includes(cur.rank_slice) ? valueCur : 0,
      });
    }
    return newList;
  }, []);

  results.forEach((result) => {
    let totalPercentage = 0;

    rankList
      .forEach((rank) => {
        const percentageValue = Math.round((result[rank]! / result.total) * 100);
        result[`${rank}-percentage`] = percentageValue;
        totalPercentage += percentageValue;
      });

    if (totalPercentage !== 100) {
      const rankToUpdate = rankList.find((rank) => result[`${rank}-percentage`]);
      if (rankToUpdate) result[`${rankToUpdate}-percentage`]! += (100 - totalPercentage);
    }
  });

  return results;
}

export function formatLegend<T extends { category_brand: NotorietyType }>(
  data: T[],
  groupByKey: keyof T extends string ? keyof T : never,
  accumulateKeys: (keyof T extends string ? keyof T : never)[],
  graphType: keyof typeof GRAPH_TYPES,
): FormatedLegendType<typeof accumulateKeys[number], typeof groupByKey[number]>[] {
  type FormatedType = FormatedLegendType<typeof groupByKey[number], typeof accumulateKeys[number]>;

  const result = data.filter(
    (obj) => {
      if (graphType === GRAPH_TYPES.rank_brand.label) return obj.category_brand === 'Marque';
      if (
        graphType === GRAPH_TYPES.rank_not_brand.label
      ) return obj.category_brand === 'Hors-Marque';
      return true;
    },
  ).reduce((acc: FormatedType[], cur) => {
    const prevValue = acc.find((values) => values[groupByKey] === (cur[groupByKey] as string));
    if (prevValue) {
      accumulateKeys.forEach((key) => {
        const curAccumulateValue = prevValue[key];
        const accAccumulateValue = cur[key];
        // @ts-ignore
        prevValue[key] = (curAccumulateValue + accAccumulateValue);
      });
    } else {
      const accumulateValues = accumulateKeys
        .reduce((acc2, curKey) => ({
          ...acc2,
          [curKey]: cur[curKey],
        }), {} as { [key in typeof accumulateKeys[number]]: number });

      const newValue: FormatedType = {
        [groupByKey]: cur[groupByKey],
        category_brand: cur.category_brand,
        ...accumulateValues,
      } as FormatedType;

      acc.push(newValue);
    }
    return acc;
  }, []);
  return result;
}

export function formatByUrlsNotoriety (
  data: GetAllUrlsPositionningType[],
  datavizMode: string,
) {
  const results = data.reduce((acc: FormatedUrlsNotoriety[], cur) => {
    const myCategory = acc.find((obj) => (
      obj.category_name === (cur.category_name ?? 'Non catégorisée')
    ));

    let newList = [];

    let valueCur = 0;
    if (datavizMode === METRICS[0]) {
      valueCur = cur.rank;
    } else if (datavizMode === METRICS[1]) {
      valueCur = cur.search_volume;
    } else if (datavizMode === METRICS[2]) {
      valueCur = cur.traffic;
    }

    if (myCategory !== undefined) {
      const totalValue = Object.keys(myCategory)
        .filter((key) => NOTORIETY.includes(key as any))
        .map((key) => myCategory[key as NotorietyType]!)
        .reduce((total: number, value: number) => total + value, 0) + valueCur;

      newList = [
        ...acc.filter((obj) => (
          obj.category_name !== (cur.category_name ?? 'Non catégorisée')
        )),
        {
          ...myCategory,
          [cur.category_brand]: (myCategory[cur.category_brand] ?? 0) + valueCur,
          total: totalValue,
        },
      ];
    } else {
      newList = [...acc];
      newList.push({
        category_name: (cur.category_name ?? 'Non catégorisée'),
        [cur.category_brand]: valueCur,
        total: valueCur,
      });
    }
    return newList;
  }, []);

  results.forEach((result: any) => {
    let totalPercentage = 0;

    NOTORIETY
      .forEach((notoriety) => {
        const percentageValue = Math.round((result[notoriety] / result.total) * 100);
        result[`${notoriety}-percentage`] = percentageValue;
        totalPercentage += percentageValue;
      });

    if (totalPercentage !== 100) {
      const notorietyToUpdate = NOTORIETY.find(
        (notoriety) => result[`${notoriety}-percentage`],
      );
      if (notorietyToUpdate) result[`${notorietyToUpdate}-percentage`] += (100 - totalPercentage);
    }
  });

  return results;
}

export function formatByUrlsDataMultiple<T extends string>(
  data: GetAllUrlsPositionningType[],
  datavizMode: string,
  graphType: keyof typeof GRAPH_TYPES,
  actorsList: T[] = [],
): FormatedUrlsDataMultiple<T>[] {
  const uncategorizedName = 'Non catégorisée';
  type FormatedType = FormatedUrlsDataMultiple<T>;

  const emptyCategories = actorsList.reduce((acc, cur) => (
    {
      ...acc,
      [cur]: 0,
      [`${cur}-percentage`]: 0,
    }
  ), {} as ActorsSliceListPercentage<typeof actorsList[number]>
  & ActorsSliceList<typeof actorsList[number]>);

  const results = data.filter(
    (obj) => {
      if (graphType === GRAPH_TYPES.rank_brand.label) return obj.category_brand === NOTORIETY[0];
      if (
        graphType === GRAPH_TYPES.rank_not_brand.label
      ) return obj.category_brand === NOTORIETY[1];
      return true;
    },
  ).reduce((acc: FormatedType[], cur) => {
    const categoryName = cur.category_name ?? uncategorizedName;
    let valueCur = 0;
    if (datavizMode === METRICS[0]) {
      valueCur = cur.rank;
    } else if (datavizMode === METRICS[1]) {
      valueCur = cur.search_volume;
    } else if (datavizMode === METRICS[2]) {
      valueCur = cur.traffic;
    }

    const findCategory = acc.find((value) => value.category_name === categoryName);
    let newValue: FormatedUrlsDataMultiple<T>;

    if (!findCategory) {
      newValue = {
        ...emptyCategories,
        [cur.actor_name]: valueCur,
        total: valueCur,
        category_name: categoryName,
      };

      acc.push(newValue);
    } else {
      const actorValue = findCategory[cur.actor_name as keyof typeof findCategory] as number;

      const newCategory = {
        ...findCategory,
        total: findCategory.total + valueCur,
        [cur.actor_name]: actorValue + valueCur,
      } as FormatedType;

      const curIndex = acc.findIndex((value) => value.category_name === categoryName);

      acc[curIndex] = newCategory;
    }

    return acc;
  }, []);

  results.forEach((result, i) => {
    let totalPercentage = 0;
    let newResult: (typeof result) | undefined;
    let resultFinal = result;

    actorsList
      .forEach((actor) => {
        const percentageValue = Math.round((result[actor]! / result.total) * 100) || 0;
        newResult = {
          ...(newResult ?? result),
          [`${actor}-percentage`]: percentageValue,
        };
        totalPercentage += percentageValue;
      });
    if (newResult) {
      results[i] = newResult;
      resultFinal = newResult;
    }
    if (totalPercentage !== 100) {
      const actorToUpdate = actorsList.find(
        (actor) => {
          const finalKey = `${actor}-percentage` as keyof FormatedUrlsDataMultiple<T>;
          return resultFinal[finalKey];
        },
      );

      if (actorToUpdate) {
        const updateKey = `${actorToUpdate}-percentage` as keyof FormatedUrlsDataMultiple<T>;
        const actorPercentage = resultFinal[updateKey] as number;
        results[i] = {
          ...resultFinal,
          [`${actorToUpdate}-percentage`]: actorPercentage + (100 - totalPercentage),
        };
      }
    }
  });

  return results;
}

export function formatByRankingTypeData(
  data: GetAllRankingTypesPositionningType[],
  datavizMode: string,
  graphType: keyof typeof GRAPH_TYPES,
  rankList: RankLabel[] = [],
): FormatedRankingTypeData[] {
  const results = data.filter(
    (obj) => {
      if (graphType === GRAPH_TYPES.rank_brand.label) return obj.category_brand === NOTORIETY[0];
      if (
        graphType === GRAPH_TYPES.rank_not_brand.label
      ) return obj.category_brand === NOTORIETY[1];
      return true;
    },
  ).reduce((acc: FormatedRankingTypeData[], cur) => {
    const myRankingType = acc.find((obj: any) => (
      obj.ranking_type === cur.ranking_type
    ));

    let newList: FormatedRankingTypeData[] = [];

    let valueCur = 0;
    if (datavizMode === METRICS[0]) {
      valueCur = cur.rank;
    } else if (datavizMode === METRICS[1]) {
      valueCur = cur.search_volume;
    } else if (datavizMode === METRICS[2]) {
      valueCur = cur.traffic;
    }

    if (myRankingType !== undefined) {
      const newRankSliceValue = (
        (cur.rank_slice in myRankingType ? myRankingType[cur.rank_slice]! : 0)
      ) + valueCur;

      const totalValue = Object.keys(myRankingType)
        .filter((key) => rankList.includes(key as RankLabel))
        .map((key) => myRankingType[key as RankLabel]!)
        .reduce((total, value) => total + value, 0)
        + (rankList.includes(cur.rank_slice) ? valueCur : 0);

      newList = [
        ...acc.filter((obj: any) => (
          obj.ranking_type !== cur.ranking_type
        )),
        {
          ...myRankingType,
          [cur.rank_slice]: newRankSliceValue,
          total: totalValue,
        },
      ];
    } else {
      newList = [...acc];
      const emptyRanks: {
        [key: string]: number
      } = {};
      rankList.forEach((rank) => {
        emptyRanks[rank as string] = 0;
      });
      newList.push({
        ...emptyRanks,
        actor_name: cur.actor_name,
        ranking_type: cur.ranking_type,
        [cur.rank_slice]: valueCur,
        total: rankList.includes(cur.rank_slice) ? valueCur : 0,
      });
    }
    return newList;
  }, []);

  results.forEach((result) => {
    let totalPercentage = 0;

    rankList
      .forEach((rank) => {
        const percentageValue = Math.round((result[rank]! / result.total) * 100);
        result[`${rank}-percentage`] = percentageValue;
        totalPercentage += percentageValue;
      });

    if (totalPercentage !== 100) {
      const rankToUpdate = rankList.find((rank) => result[`${rank}-percentage`]);
      if (rankToUpdate) result[`${rankToUpdate}-percentage`]! += (100 - totalPercentage);
    }
  });

  return results;
}

export function formatByRankingTypeNotoriety (
  data: GetAllRankingTypesPositionningType[],
  datavizMode: string,
) {
  const results = data.reduce((acc: FormatedRankingTypesNotoriety[], cur) => {
    const myRankingType = acc.find((obj: any) => (
      obj.ranking_type === cur.ranking_type
    ));

    let newList = [];

    let valueCur = 0;
    if (datavizMode === METRICS[0]) {
      valueCur = cur.rank;
    } else if (datavizMode === METRICS[1]) {
      valueCur = cur.search_volume;
    } else if (datavizMode === METRICS[2]) {
      valueCur = cur.traffic;
    }

    if (myRankingType !== undefined) {
      const totalValue = Object.keys(myRankingType)
        .filter((key) => NOTORIETY.includes(key as any))
        .map((key) => myRankingType[key as NotorietyType]!)
        .reduce((total: number, value: number) => total + value, 0) + valueCur;

      newList = [
        ...acc.filter((obj: any) => (
          obj.ranking_type !== cur.ranking_type
        )),
        {
          ...myRankingType,
          [cur.category_brand]: (myRankingType[cur.category_brand] ?? 0) + valueCur,
          total: totalValue,
        },
      ];
    } else {
      newList = [...acc];
      newList.push({
        ranking_type: cur.ranking_type,
        [cur.category_brand]: valueCur,
        total: valueCur,
      });
    }
    return newList;
  }, []);

  results.forEach((result: any) => {
    let totalPercentage = 0;

    NOTORIETY
      .forEach((notoriety) => {
        const percentageValue = Math.round((result[notoriety] / result.total) * 100);
        result[`${notoriety}-percentage`] = percentageValue;
        totalPercentage += percentageValue;
      });

    if (totalPercentage !== 100) {
      const notorietyToUpdate = NOTORIETY.find(
        (notoriety) => result[`${notoriety}-percentage`],
      );
      if (notorietyToUpdate) result[`${notorietyToUpdate}-percentage`] += (100 - totalPercentage);
    }
  });

  return results;
}

export function formatByRankingTypesDataMultiple<T extends string>(
  data: GetAllRankingTypesPositionningType[],
  datavizMode: string,
  graphType: keyof typeof GRAPH_TYPES,
  actorsList: T[] = [],
): FormatedRankingTypesDataMultiple<T>[] {
  const uncategorizedName = 'Non catégorisée';
  type FormatedType = FormatedRankingTypesDataMultiple<T>;

  const emptyCategories = actorsList.reduce((acc, cur) => (
    {
      ...acc,
      [cur]: 0,
      [`${cur}-percentage`]: 0,
    }
  ), {} as ActorsSliceListPercentage<typeof actorsList[number]>
  & ActorsSliceList<typeof actorsList[number]>);

  const results = data.filter(
    (obj) => {
      if (graphType === GRAPH_TYPES.rank_brand.label) return obj.category_brand === NOTORIETY[0];
      if (
        graphType === GRAPH_TYPES.rank_not_brand.label
      ) return obj.category_brand === NOTORIETY[1];
      return true;
    },
  ).reduce((acc: FormatedType[], cur) => {
    const rankingType = cur.ranking_type ?? uncategorizedName;
    let valueCur = 0;
    if (datavizMode === METRICS[0]) {
      valueCur = cur.rank;
    } else if (datavizMode === METRICS[1]) {
      valueCur = cur.search_volume;
    } else if (datavizMode === METRICS[2]) {
      valueCur = cur.traffic;
    }

    const findRankingType = acc.find((value) => value.ranking_type === rankingType);
    let newValue: FormatedRankingTypesDataMultiple<T>;

    if (!findRankingType) {
      newValue = {
        ...emptyCategories,
        [cur.actor_name]: valueCur,
        total: valueCur,
        ranking_type: rankingType,
      };

      acc.push(newValue);
    } else {
      const actorValue = findRankingType[cur.actor_name as keyof typeof findRankingType] as number;

      const newRankingType = {
        ...findRankingType,
        total: findRankingType.total + valueCur,
        [cur.actor_name]: actorValue + valueCur,
      } as FormatedType;

      const curIndex = acc.findIndex((value) => value.ranking_type === rankingType);

      acc[curIndex] = newRankingType;
    }

    return acc;
  }, []);

  results.forEach((result, i) => {
    let totalPercentage = 0;
    let newResult: (typeof result) | undefined;
    let resultFinal = result;

    actorsList
      .forEach((actor) => {
        const percentageValue = Math.round((result[actor]! / result.total) * 100) || 0;
        newResult = {
          ...(newResult ?? result),
          [`${actor}-percentage`]: percentageValue,
        };
        totalPercentage += percentageValue;
      });
    if (newResult) {
      results[i] = newResult;
      resultFinal = newResult;
    }
    if (totalPercentage !== 100) {
      const actorToUpdate = actorsList.find(
        (actor) => {
          const finalKey = `${actor}-percentage` as keyof FormatedRankingTypesDataMultiple<T>;
          return resultFinal[finalKey];
        },
      );

      if (actorToUpdate) {
        const updateKey = `${
          actorToUpdate
        }-percentage` as keyof FormatedRankingTypesDataMultiple<T>;
        const actorPercentage = resultFinal[updateKey] as number;
        results[i] = {
          ...resultFinal,
          [`${actorToUpdate}-percentage`]: actorPercentage + (100 - totalPercentage),
        };
      }
    }
  });

  return results;
}

export function formatByRankingType(
  datas: any[],
  rankingTypes: string[],
  actorNames: string[],
  dataKeys: any[],
) {
  const AllActorsNameModel = actorNames.reduce((prev, cur) => {
    prev[cur] = 0;
    return prev;
  }, { } as Record<string, number>);

  const tempDatas: Record<string, number | string>[] = rankingTypes.map((rt) => ({
    name: rt,
    ...AllActorsNameModel,
  }));

  datas.forEach((data) => {
    const toEdit = tempDatas.find((fData) => data.ranking_type_name === fData.name);
    if (toEdit) toEdit[data.actor_name] = data.count;
  });

  const finalDatas = tempDatas.reduce((prev, cur) => {
    const total = dataKeys.reduce((prevTotal: number, key) => {
      const { dataKey } = key;
      if (typeof cur[dataKey] === 'number') return prevTotal + cur[dataKey];
      return prevTotal;
    }, 0);

    const temp = cur;

    dataKeys.forEach((key) => {
      const { dataKey } = key;

      if (typeof cur[dataKey] === 'number') {
        if (cur[dataKey] > 0) {
          temp[`${dataKey}-percentage`] = Math.round((cur[dataKey] / total) * 100);
        } else temp[`${dataKey}-percentage`] = 0;
      }
    });

    prev.push(temp);

    return prev;
  }, [] as Record<string, number | string>[]);

  return finalDatas;
}

export function formatByRankingTypeAndPeriodsDatas(
  datas: any[] | undefined,
  dataKeys: {
    dataKey: string;
    color: string | undefined;
    stackId: number;
  }[],
  excludeRankingTypes: number[] | undefined,
): Record<string, number | string>[] {
  if (!datas) return [];

  const result = datas.reduce((acc: Record<string, number | string>[], cur) => {
    if (excludeRankingTypes?.includes(cur.ranking_type_id as number)) return acc;

    return [
      ...acc.filter((obj: any) => obj.periode !== cur.periode),
      {
        ...(
          acc.find((obj: any) => obj.periode === cur.periode)
        ?? {}
        ),
        periode: cur.periode,
        total: (acc.find((obj: any) => obj.periode === cur.periode)?.total ?? 0) + cur.count,
        ...((cur.count as number) > 0 ? { [cur.ranking_type_name]: cur.count } : {}),
      },
    ];
  }, [] as Record<string, number | string>[]) as Record<string, number | string>[];

  return result.reduce((acc, cur) => {
    dataKeys.forEach(({ dataKey }) => {
      if (typeof cur[dataKey] === 'number' && typeof cur.total === 'number') {
        cur[`${dataKey}-percentage`] = Math.round((cur[dataKey] / cur.total) * 100);
      }
    });

    acc.push(cur);

    return acc;
  }, [] as Record<string, number | string>[]);
}
