import TabLinkType from 'components/link/types';
import { Project } from 'features/projects/types';
import { Analysis } from 'features/serpAnalysis/types';
import { generateAnalysisUrl } from 'utils/urlManipulation';

import { serpPhysionomyHistoryLabel, serpPhysionomyLabel } from './config';
import { SERP_PHYSIONOMY_HISTORY, SERP_PHYSIONOMY } from '../ROUTES';

function getPhysionomyNavigation(
  project: Project,
  analysis?: Analysis,
): TabLinkType[] {
  return [
    {
      label: 'Comparaison des analyses',
      path: `${
        generateAnalysisUrl(
          project.id,
          project.name,
          analysis?.id ?? 0,
          analysis?.label ?? '',
          serpPhysionomyLabel,
          undefined,
        )}`,
      pattern: SERP_PHYSIONOMY,
    }, {
      label: 'Évolution de l\'écosystème',
      path: `${
        generateAnalysisUrl(
          project.id,
          project.name,
          analysis?.id ?? 0,
          analysis?.label ?? '',
          serpPhysionomyHistoryLabel,
          undefined,
        )}`,
      pattern: SERP_PHYSIONOMY_HISTORY,
    },
  ];
}

export default getPhysionomyNavigation;
