import { createTheme } from '@mui/material/styles';
import type {} from '@mui/x-data-grid/themeAugmentation';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    body3: React.CSSProperties;
    bold: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    body3?: React.CSSProperties;
    bold?: React.CSSProperties;
  }

  interface Palette {
    dataviz: string[];
  }

  interface PaletteOptions {
    dataviz?: string[];
  }

  interface Theme {
    fontSize: {
      xlarge: string;
      large: string;
      mediumLarge: string;
      medium: string;
      mediumsmall: string;
      middlesmall: string;
      xsmall: string;
    }
  }

  interface ThemeOptions {
    fontSize?: {
      xlarge?: string;
      large?: string;
      mediumLarge?: string;
      medium?: string;
      mediumsmall?: string;
      middlesmall?: string;
      xsmall?: string;
    }
  }
}
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body3: true;
    bold: true;
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#0A054F',
      dark: '#08043B',
      light: '#362EEB',
    },
    secondary: {
      main: '#5DEBB3',
      dark: '#41A47D',
      light: '#7DEFC2',
    },
    info: {
      main: '#A1A5B7',
      dark: '#5D616B',
      light: '#F5F8FB',
    },
    error: {
      main: '#FF1744',
      dark: '#B2102F',
      light: '#FF4569',
    },
    dataviz: [
      '#C7434B', // 1
      '#E58351', // 2
      '#F7C26B', // 3
      '#A5CE8A', // 4
      '#5E9975', // 5
      '#29655A', // 6
      '#54B8C3', // 7
      '#77E3FF', // 8
      '#5AC4FF', // 9
      '#B390FF', // 10
      '#FE86E2', // 11
      '#DE599C', // 12
    ],
    background: {
      default: '#e7ecef',
    },
  },
  fontSize: {
    xlarge: '1.5rem',
    large: '1.25rem',
    mediumLarge: '1rem',
    medium: '0.875rem',
    mediumsmall: '0.850rem',
    middlesmall: '0.8125rem',
    xsmall: '0.750rem',
  },
  typography: {
    fontFamily: [
      'Roboto',
      'sans-serif',
    ].join(','),
    h1: {
      fontSize: '24px',
      fontWeight: 500,
      marginBottom: '10px',
    },
    h2: {
      fontSize: '20px',
      fontWeight: 400,
      lineHeight: '23,44px',
      color: 'black',
    },
    body3: {
      fontSize: '0.750rem',
    },
    fontWeightRegular: 400,
    fontWeightBold: 700,
    bold: {
      fontWeight: 'bold',
    },
  },
  spacing: 8,
  components: {
    MuiChip: {
      styleOverrides: {
        root: () => ({
          borderRadius: '5px',

        }),
        colorError: () => ({
          backgroundColor: '#DC3545',
        }),
        colorSuccess: () => ({
          backgroundColor: '#198754',
        }),
        colorWarning: () => ({
          backgroundColor: '#FFC107',
        }),
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        underline: {
          border: 'none',
        },
        root: () => ({
          color: theme.palette.info.dark,
          fontSize: '.875rem',
          backgroundColor: theme.palette.info.light,
          borderRadius: '5px',
          '&.Mui-error': {
            color: theme.palette.error.main,
            border: '1px solid',
            borderColor: theme.palette.error.main,
          },
          '&.Mui-focused': {
            backgroundColor: theme.palette.info.light,
            border: '1px solid',
            borderColor: theme.palette.primary.light,
            '&.MuiInputLabel-root': {
              color: theme.palette.primary.light,
            },
          },
          '&&&:before': { border: '0' },
          '&&&:after': { border: '0' },
        }),
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: () => ({
          fontSize: '0.875rem',
          '&.Mui-focused': {
            color: theme.palette.primary.light,
          },
          '&.MuiInputLabel-shrink': {
            fontSize: '0.825rem',
          },
        }),
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          fontSize: '0.825rem',
        },
        root: () => ({
          backgroundColor: '#FFF',
        }),
      },
    },
    MuiAlert: {
      styleOverrides: {
        standardInfo: {
          backgroundColor: '#E5F6FD',
          color: '#014361',
        },
        standardWarning: {
          backgroundColor: '#FEF7DE',
        },
        icon: ({ ownerState }) => ({
          ...(ownerState.color === 'info' && {
            color: '#1893D5 !important',
          }),
          fontSize: '25px',
        }),
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        separator: {
          marginRight: '4px',
          marginLeft: '4px',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: () => ({
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
          textDecoration: 'none',
          textTransform: 'none',
        }),
        containedPrimary: () => ({
          backgroundColor: theme.palette.primary.light,
          color: '#FFFFFF',
          '&:hover': {
            boxShadow: 'none',
            backgroundColor: theme.palette.secondary.light,
            color: theme.palette.primary.light,
          },
          '&:active': {
            backgroundColor: theme.palette.secondary.light,
            color: theme.palette.primary.light,
          },
        }),
        containedInfo: () => ({
          backgroundColor: theme.palette.info.light,
          color: theme.palette.primary.light,
          '&:hover': {
            backgroundColor: theme.palette.info.main,
            color: theme.palette.primary.light,
          },
          '&:active': {
            backgroundColor: theme.palette.info.light,
            color: theme.palette.primary.light,
          },
        }),
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        row: () => ({
          '&.Mui-selected': {
            backgroundColor: '#FFFFFF',
            '&:hover': {
              backgroundColor: theme.palette.info.light,
            },
          },
        }),
        columnHeader: () => ({
          backgroundColor: '#FFF',
          '&:focus-within': {
            outline: 0,
          },
        }),
        cell: () => ({
          display: 'flex',
          alignItems: 'center',
          lineHeight: 'inherit',
          '&:focus-within': {
            outline: 0,
          },
        }),
        columnSeparator: () => ({
          visibility: 'hidden',
        }),
        root: () => ({
          flex: '1',
          padding: '',
          borderRadius: '20px',
          border: '0px',
          fontColor: 'grey',
          fontSize: '0.750rem',
          height: '100%',
          width: '100%',
          marginTop: '2rem',
          backgroundColor: 'white',
          '& .MuiDataGrid-cell:focus-within': {
            outline: 0,
          },
          '& .MuiDataGrid-columnHeader:focus-within': {
            outline: 0,
          },
          '& .MuiDataGrid-row:hover': {
            backgroundColor: theme.palette.info.light,
          },
          '& .MuiDataGrid-columnSeparator': {
            visibility: 'hidden',
          },
        }),
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: () => ({
          '&.Mui-checked': {
            color: theme.palette.primary.light,
            borderColor: theme.palette.primary.light,
          },
        }),
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: () => ({
          '&.Mui-checked': {
            color: theme.palette.primary.light,
            borderColor: theme.palette.primary.light,
          },
          '&.Mui-disabled': {
            color: theme.palette.info.main,
            borderColor: theme.palette.primary.light,
          },
          '&.MuiCheckbox-indeterminate': {
            color: theme.palette.primary.light,
            borderColor: theme.palette.primary.light,
          },
        }),
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: () => ({
          '& .MuiTab-root.Mui-selected': {
            color: theme.palette.primary.light,
          },
          '& .MuiTabs-indicator': {
            backgroundColor: theme.palette.primary.light,
          },
        }),
      },
    },
  },
});

export default theme;
