import React, { useMemo } from 'react';

import { Box } from '@mui/material';

import globalTheme from 'common/theme';
import { ActorType } from 'features/actors/types';
import getActorColor from 'features/actors/utils/format';
import { formatByRankingType } from 'features/dataviz/utils/formatData';

import BarChartVerticalComponent from '../generic/charts/BarChartVerticalComponent';
import { getBarColor } from '../seoPerformance/byActor/performanceByActorFormat';

type GroupResultGraphProps = {
  datas: any[],
  actors: ActorType[],
  rankingTypes: string[],
  actorNames: string[],
  isPageLoading?: boolean,
  handleClick?: Function
  showPercentage?: boolean,
  showValue?: boolean,
  hideYAxisLabel?: boolean,
}

function GroupResultsByRankingTypeGraph(props: GroupResultGraphProps) {
  const {
    datas,
    actors,
    isPageLoading,
    handleClick,
    rankingTypes,
    actorNames,
    showPercentage,
    showValue,
    hideYAxisLabel,
  } = props;

  // If 'autres' is in the result set, we add it into the actor names list
  if (datas.find((value) => value.actor_name === 'autres')) {
    actorNames.push('autres');
  }

  const dataKeys: any[] = [{
    dataKey: 'autres',
    color: globalTheme.palette.info.main,
    stackId: 1,
  }];

  const actorsDataKeys = actors.map((a) => ({
    dataKey: a.name,
    color: getActorColor(a.is_client, a.dataviz_color),
    stackId: 1,
  }));

  if (actorsDataKeys) dataKeys.push(...actorsDataKeys);

  const formatedDatas = useMemo(
    () => formatByRankingType(datas, rankingTypes, actorNames, dataKeys),
    [datas, rankingTypes, actorNames],
  );

  if (!datas || datas.length === 0) {
    return (
      <Box width="100%" textAlign="center" alignContent="center">
        vide
      </Box>
    );
  }

  return (
    <BarChartVerticalComponent
      datas={formatedDatas}
      title=""
      dataKeys={dataKeys.sort(
        (a, b) => (b.dataKey === 'autres' ? -1 : a.dataKey.localeCompare(b.dataKey)),
      )}
      isPageLoading={isPageLoading}
      tickMargin={5}
      handleClick={handleClick}
      truncateTickLabel={false}
      isAxisLabelBold={false}
      hideYAxisLabel={hideYAxisLabel}
      withTooltip
      containerAspect={1}
      setBarColor={(actorName: string) => getBarColor(actorName, actors)}
      showLegend
      centerLabelName={showValue}
      percentLabel={showPercentage}
    />
  );
}

export default GroupResultsByRankingTypeGraph;
