import React from 'react';
import { Link, matchRoutes, useLocation } from 'react-router-dom';

import { Tab, Tabs } from '@mui/material';
import { Box } from '@mui/system';

import TabLinkType from './types';

type TabsLinkProps = {
  links: TabLinkType[],
  defaultValue?: string,
}

function TabsLink(props: TabsLinkProps) {
  const { links, defaultValue = '' } = props;

  const location = useLocation();

  const value = links.find((link) => matchRoutes(
    [{ path: link.path }],
    location.pathname,
  ))?.pattern ?? defaultValue;

  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs
        role="navigation"
        value={value}
      >
        {links.map((link) => (
          <Tab
            component={Link}
            key={link.pattern}
            value={link.pattern}
            label={link.label}
            to={link.path}
            sx={{ fontSize: (theme) => theme.fontSize.mediumsmall }}
          />
        ))}
      </Tabs>
    </Box>
  );
}

export default TabsLink;
