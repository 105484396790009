import React from 'react';

import {
  Box,
  styled,
} from '@mui/material';

import { leftDrawerWidth, rightDrawerWidth } from 'features/navbar/config';

const PREFIX = 'MainLayout';

const classes = {
  background: `${PREFIX}-background`,
  container: `${PREFIX}-container`,
  innerCont: `${PREFIX}-innerCont`,
};

const StyledBox = styled(
  Box,
  { shouldForwardProp: (prop) => prop !== 'open' },
)<{ open?: boolean; }>(({ theme, open }) => ({
  [`& .${classes.background}`]: {
    justifyContent: 'center',
    minHeight: '100vh',
    width: open
      ? `calc(100% - ${leftDrawerWidth}px - ${rightDrawerWidth}px)`
      : `calc(100% - ${leftDrawerWidth}px)`,
    flex: 1,
    flexGrow: 1,
    position: 'absolute',
    zIndex: -1,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  },
  [`& .${classes.container}`]: {
    justifyContent: 'left',
    width: open ? '96%' : '90%',
    alignItems: 'center',
    display: 'flex',
    marginTop: '1rem',
    marginLeft: open ? '2%' : '5%',
    marginRight: open ? '2%' : '5%',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  },
  [`& .${classes.innerCont}`]: {
    width: '100%',
    flexGrow: 1,
  },
}));

function MainLayout({ children, isOpen }: any) {
  return (
    <StyledBox open={isOpen}>
      <Box className={classes.background}>
        <Box className={classes.container}>
          <Box className={classes.innerCont} marginBottom={2}>{children}</Box>
        </Box>
      </Box>
    </StyledBox>
  );
}

export default MainLayout;
