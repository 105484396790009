import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import moment from 'moment';

import useActors from 'features/actors/state/useActors';
import CATEGORY_TYPE_ID from 'features/categoryGroups/CONSTANTS';
import useCategoryGroups from 'features/categoryGroups/state/useCategoryGroups';
import { DATA_SOURCE } from 'features/dataFiles/CONSTANT';
import {
  PERFORMANCE_QUERIES,
  defaultFiltersValue,
} from 'features/performance/CONSTANT';
import { PerformanceFiltersType, PerformancePages } from 'features/performance/types';
import DefaultPerformanceFilters from 'features/performance/utils/DefaultPerformanceFilters';
import usePeriod from 'features/period/state/usePeriod';
import useRankingTypes from 'features/rankingTypes/state/useRankingTypes';
import useSerpAnalyses from 'features/serpAnalysis/state/useSerpAnalyses';
import { RankingEvolutionFilters } from 'features/serpAnalysis/types';

function withPerformanceFilters(scopeId: number, page: PerformancePages) {
  const [searchParams] = useSearchParams();
  const { periods, isLoading: isPeriodLoading } = usePeriod(
    scopeId,
    [DATA_SOURCE.semrush.id],
  );
  const { actors, isLoading: isActorsLoading } = useActors({ scopeId });
  const {
    categoryGroups,
    isLoading: isCategoryLoading,
  } = useCategoryGroups(scopeId, CATEGORY_TYPE_ID.url);
  const { serpAnalyses, isLoading: isSerpAnalysesLoading } = useSerpAnalyses({ scopeId });
  const { rankingTypes } = useRankingTypes();

  const defaultFilters = new DefaultPerformanceFilters(
    page,
    periods,
    actors,
    serpAnalyses,
    categoryGroups,
    rankingTypes,
  );

  const [hasFirstLoadedFilters, setHasFirstLoadedFilters] = useState(false);
  const [filtered, setFiltered] = useState(searchParams.size > 0);

  const [performanceFilters, setPerformanceFilters] = useState<RankingEvolutionFilters>({
    ...defaultFiltersValue,
  });

  const isFullyLoaded = useMemo(
    () => [
      !isPeriodLoading,
      !isActorsLoading,
      !isCategoryLoading,
      !isSerpAnalysesLoading,
    ].every(Boolean),
    [isPeriodLoading, isActorsLoading, isCategoryLoading, isSerpAnalysesLoading],
  );

  const periodParam = searchParams.get(PERFORMANCE_QUERIES.period);
  const rankSliceParams = searchParams.getAll(PERFORMANCE_QUERIES.rankSlice);
  const metricParams = searchParams.get(PERFORMANCE_QUERIES.metric);
  const actorIdsParam = searchParams.getAll(PERFORMANCE_QUERIES.actorIds);
  const serpAnalysisIdsParam = searchParams.getAll(PERFORMANCE_QUERIES.serpAnalysisIds);
  const categoryGroupIdParam: number | null = Number(
    searchParams.get(PERFORMANCE_QUERIES.categoryGroupId),
  );
  const categoryIdsParam = searchParams.getAll(PERFORMANCE_QUERIES.categoryIds);
  const rankingTypeIdsParams = searchParams.getAll(PERFORMANCE_QUERIES.rankingTypeIds);
  const bestRankModeParam = searchParams.get(PERFORMANCE_QUERIES.bestRankMode);
  const includeUncategorizedParam = searchParams.get(PERFORMANCE_QUERIES.includeUncategorized);

  const resetFilters = () => setPerformanceFilters({
    ...defaultFiltersValue,
    periodeBegin: defaultFilters.getDefaultPeriodBegin(),
    periodeEnd: defaultFilters.getDefaultPeriodEnd(),
    actorIds: defaultFilters.getDefaultActors(),
    categoryGroupId: defaultFilters.getDefaultCategoryGroup(),
    categoryIds: defaultFilters.getDefaultCategory(),
    rankingTypeId: defaultFilters.getDefaultRankingTypeIds(null),
  });

  useEffect(() => {
    if (isFullyLoaded && !hasFirstLoadedFilters) {
      const periodeBegin = defaultFilters.getDefaultPeriodBegin(periodParam);
      const periodeEnd = defaultFilters.getDefaultPeriodEnd(periodParam);
      const actorIds = defaultFilters.getDefaultActors(actorIdsParam);
      const serpAnalysisIds = defaultFilters.getDefaultSerpAnalysis(serpAnalysisIdsParam);
      const categoryGroupId = defaultFilters.getDefaultCategoryGroup(categoryGroupIdParam);
      const categoryIds = defaultFilters.getDefaultCategory(categoryGroupIdParam, categoryIdsParam);
      const bestRankMode = defaultFilters.getDefaultBestRankMode(bestRankModeParam);
      const rankSliceIds = defaultFilters.getDefaultRankSlice(rankSliceParams);
      const datavizMode = defaultFilters.getDefaultMetric(metricParams);
      const rankingTypeId = defaultFilters.getDefaultRankingTypeIds(
        rankingTypeIdsParams,
      );
      const includeUncategorized = defaultFilters.getDefaultIncludeUncategorized(
        includeUncategorizedParam,
      );
      const showCategoryFilter = !!categoryGroupId && (
        !!categoryIds.length
        || includeUncategorized
      );

      setPerformanceFilters({
        ...performanceFilters,
        periodeBegin,
        periodeEnd,
        actorIds,
        categoryIds: showCategoryFilter ? categoryIds : [],
        categoryGroupId: showCategoryFilter ? categoryGroupId : undefined,
        serpAnalysisIds,
        bestRankMode,
        rankSliceIds,
        datavizMode,
        rankingTypeId,
        includeUncategorized: showCategoryFilter ? includeUncategorized : false,
      });

      setHasFirstLoadedFilters(true);
    }
  }, [isFullyLoaded]);

  const handleFiltersUpdate = (newFilters: PerformanceFiltersType) => {
    setPerformanceFilters((prev) => ({
      ...prev,
      periodeBegin: moment(newFilters.period).startOf('month').format(),
      periodeEnd: moment(newFilters.period).endOf('month').format(),
      rankSliceIds: newFilters.rankSlices,
      datavizMode: newFilters.metric,
      actorIds: newFilters.actorIds,
      categoryGroupId: newFilters.categoryGroupId || undefined,
      categoryIds: (newFilters.categoryGroupId && newFilters.categoryIds?.length)
        ? newFilters.categoryIds
        : undefined,
      serpAnalysisIds: newFilters.serpAnalysisIds,
      rankingTypeId: newFilters.rankingTypeIds,
      bestRankMode: newFilters.bestRankMode,
      includeUncategorized: newFilters.includeUncategorized,
    }));
    setFiltered(true);
  };

  return {
    performanceFilters,
    defaultFilters,
    handleFiltersUpdate,
    resetFilters,
    hasFirstLoadedFilters,
    filtered,
    isFullyLoaded,
  };
}

export default withPerformanceFilters;
