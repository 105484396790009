import moment from 'moment';

import { convertDate, DATE_NUMBERS_HOURS, DATE_NUMBERS_HOURS_SC_UTC } from 'utils/formatDate';

import getNextScrapDate from './serpAnalysis';
import { ANALYSIS_STATUS, DEFAULT_SCRAP_DATE } from '../CONSTANT';
import { AnalysisMinimal } from '../types';

const findNextScrappingDate = (
  analyses: AnalysisMinimal[],
  projectBudget: string,
): string => {
  if (!analyses.length) return '';

  let temp: any[] = [...analyses].filter(
    (anl: AnalysisMinimal) => anl.serp_analysis_status_id !== ANALYSIS_STATUS.IN_PROGRESS,
  );

  temp = temp.map((anl) => ({
    ...anl,
    next_scrapping: getNextScrapDate(
      anl.last_scrapping,
      anl.planning_date,
      anl.serp_analysis_planning_id,
      anl.planning_delay,
      (parseInt(projectBudget, 10) > 0),
      DATE_NUMBERS_HOURS_SC_UTC,
      true,
    ),
  })).filter((anl) => anl.next_scrapping !== DEFAULT_SCRAP_DATE && anl.next_scrapping);
  temp.sort((a, b) => {
    if (!a.next_scrapping || !b.next_scrapping) return 0;
    return (
      moment(b.next_scrapping, moment.ISO_8601).diff(
        moment(a.next_scrapping, moment.ISO_8601),
      ) < 0 ? 1 : -1);
  });

  return temp[0]?.next_scrapping ? convertDate(temp[0]?.next_scrapping, DATE_NUMBERS_HOURS) : '';
};

export default findNextScrappingDate;
