import React, { useEffect, useState } from 'react';

import {
  Button,
  CircularProgress,
  SelectChangeEvent,
  Stack,
} from '@mui/material';
import moment from 'moment';

import DropDown from 'components/dropDown/DropDown';
import { DefaultDropDownItemsType } from 'components/dropDown/type';
import MainBox from 'components/layout/MainBox';
import TitleComponent from 'features/dataviz/components/generic/charts/common/Title';
import DeviceDropDown from 'features/deviceType/components/DeviceDropDown';
import useSerpAnalysisScrappings
  from 'features/serpAnalysisScrapping/state/useSerpAnalysisScrappings';
import { sortScrappingsByDate } from 'features/serpAnalysisScrapping/utils';
import { convertDate, DATE_ENGLISH, DATE_FULL_NAME_MONTH_YEAR } from 'utils/formatDate';

import organicLimit from '../CONSTANT';
import { PhysionomyHistoryFiltersForm } from '../types';

type PhysionomyHistoryFiltersProps = {
  values: PhysionomyHistoryFiltersForm,
  onChange: (e: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<string>) => void,
  onSubmit: VoidFunction,
  onPeriodsLoaded?: (periods: string) => void,
  isLoading: boolean,
  serpAnalysisId: number,
}

const title = 'Paramétrez les données';

function PhysionomyHistoryFilters(props: PhysionomyHistoryFiltersProps) {
  const {
    values,
    onChange,
    onSubmit,
    onPeriodsLoaded,
    isLoading,
    serpAnalysisId,
  } = props;

  const [formatedPeriods, setFormatedPeriods] = useState<DefaultDropDownItemsType[]>([]);

  const {
    scrappings,
    isLoading: isSerpAnalysisLoading,
  } = useSerpAnalysisScrappings(serpAnalysisId);

  useEffect(() => {
    const scrappingsSorted = sortScrappingsByDate(scrappings)
      .filter((scrapping) => scrapping.device_type_id === values.deviceId);

    let tempFormatedPeriods: DefaultDropDownItemsType[] = [];
    if (isSerpAnalysisLoading) tempFormatedPeriods = [];
    else if (scrappingsSorted.length) {
      tempFormatedPeriods = scrappingsSorted.map(({ scrapping_date }) => ({
        label: convertDate(
          moment(scrapping_date).startOf('month').toDate(),
          DATE_FULL_NAME_MONTH_YEAR,
        ),
        value: convertDate(moment(scrapping_date).utc().startOf('month').toDate(), DATE_ENGLISH),
      })).reduce((acc: { dates: Set<unknown>, result: DefaultDropDownItemsType[] }, current) => {
        // Utiliser un Set pour dédoublonner les dates
        if (!acc.dates.has(current.value)) {
          acc.dates.add(current.value);
          acc.result.push(current);
        }
        return acc;
      }, { dates: new Set(), result: [] }).result;
    } else {
      const defaultPeriods = convertDate(moment().startOf('month').toDate(), DATE_ENGLISH);
      tempFormatedPeriods = [{
        label: convertDate(moment(defaultPeriods).toDate(), DATE_FULL_NAME_MONTH_YEAR),
        value: defaultPeriods,
      }];
    }

    setFormatedPeriods(tempFormatedPeriods);
    if (typeof tempFormatedPeriods[0]?.value === 'string') {
      onPeriodsLoaded?.(tempFormatedPeriods[0]?.value);
    }
  }, [scrappings, values.deviceId]);

  if (isLoading) {
    return (
      <MainBox BoxProps={{ justifyItems: 'center' }}>
        <TitleComponent title={title} capitalLetter />
        <CircularProgress data-testid="physionomy-filters-spinner" />
      </MainBox>
    );
  }

  return (
    <MainBox>
      <TitleComponent
        title={title}
        capitalLetter
        direction="column"
      />
      <Stack
        direction="column"
        spacing={2}
        my={2}
        data-testid="physionomy-filters-form"
      >
        <DeviceDropDown
          name="deviceId"
          onChange={onChange}
          value={values.deviceId}
          size="small"
          fontSize="xsmall"
          uniqueOptions
        />
        <DropDown
          onChange={onChange}
          options={organicLimit}
          value={values.rankLimit}
          name="rankLimit"
          size="small"
          fontSize="xsmall"
          label="Limite organique"
        />
        <DropDown
          onChange={onChange}
          options={formatedPeriods}
          value={values.periode ?? ''}
          name="periode"
          size="small"
          fontSize="xsmall"
          label="Mois"
        />
      </Stack>
      <Stack alignItems="end">
        <Button onClick={onSubmit} variant="contained">
          Analyser
        </Button>
      </Stack>
    </MainBox>
  );
}

export default PhysionomyHistoryFilters;
