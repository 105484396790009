import React from 'react';

import {
  Box, BoxProps, Typography, styled,
} from '@mui/material';

import useDocumentTitle from 'common/documentTitleHook';
import { DATA_SOURCE } from 'features/dataFiles/CONSTANT';
import DatavizContainer from 'features/dataviz/components/generic/DatavizContainer';
import { METRIC } from 'features/dataviz/CONSTANTS';
import datavizConfig from 'features/dataviz/datavizConfig';
import PerformanceFiltersBlock from 'features/performance/components/PerformanceFiltersBlock';
import withPerformanceFilters from 'features/performance/utils/withPerformanceFilters';
import AlertNoPeriods from 'features/period/component/AlertNoPeriods';
import { Project } from 'features/projects/types';
import {
  generatePerformanceUrl,
} from 'utils/urlManipulation';

const PREFIX = 'PerformancePerSerp';

const classes = {
  ctn: `${PREFIX}-ctn`,
};

const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
  [`& .${classes.ctn}`]: {
    marginTop: theme.spacing(2),
  },
}));

type PerformancePerSerpProps = {
  project?: Project,
};

function PerformancePerSerp ({ project }: PerformancePerSerpProps) {
  useDocumentTitle(
    `${project?.name} : comparer les performances SEO par fonctionnalités SERP | benhur`,
  );

  const scopeId = project?.scopes[0].id ?? 0;
  const baseUrl = generatePerformanceUrl(
    project?.id ?? 0,
    project?.name ?? '',
    undefined,
    undefined,
    'by-serp',
  );

  const page = 'serp';

  const {
    performanceFilters,
    defaultFilters,
    handleFiltersUpdate,
    resetFilters,
    hasFirstLoadedFilters,
    filtered,
    isFullyLoaded,
  } = withPerformanceFilters(scopeId, page);

  return (
    <StyledBox>
      <Typography variant="h1">
        Comparez les performances SEO par fonctionnalités SERP
      </Typography>
      {isFullyLoaded && hasFirstLoadedFilters && (
        <>
          <AlertNoPeriods
            scopeId={scopeId}
            dataSourceIds={[DATA_SOURCE.semrush.id]}
            project={project}
          />
          <PerformanceFiltersBlock
            onFiltersChange={handleFiltersUpdate}
            defaultFilters={defaultFilters}
            resetFilters={resetFilters}
            baseUrl={baseUrl}
            scopeId={scopeId ?? 0}
            page={page}
          />
        </>
      )}
      {filtered && (
        <Box className={classes.ctn}>
          <DatavizContainer
            idScope={scopeId || 0}
            datavizComp={
              datavizConfig[METRIC.get_all_by_ranking_type.label]
            }
            title=""
            datavizFilters={filtered ? performanceFilters : undefined}
            size="medium"
            periode=""
            height="fit-content"
            project={project}
          />
        </Box>
      )}
    </StyledBox>
  );
}

export default PerformancePerSerp;
