import { RANKING_TYPE } from 'features/dataviz/CONSTANTS';

export const featuredSnippetLabel = 'fs';
export const featuredSnippetName = 'Featured snippet';
export const featuredSnippetAbbr = 'FS';
export const peopleAlsoAskLabel = 'paa';
export const peopleAlsoAskName = 'People also ask';
export const peopleAlsoAskAbbr = 'PAA';
export const organicName = 'Recherche organique';
export const organicLabel = 'organic';
export const serpPhysionomyName = 'Physionomie des SERP';
export const serpPhysionomyLabel = 'serp';
export const serpPhysionomyHistoryLabel = 'serp/history';
export const ShareOfVoiceName = 'Part de voix';
export const ShareOfVoiceLabel = 'share-of-voice';
export const localPackLabel = 'local';
export const localPackName = 'Pack local ';
export const localPackAbbr = 'LP';
export const videoLabel = 'video';
export const videoName = 'Vidéos';
export const videoAbbr = 'Video';
export const imageLabel = 'image';
export const imageName = 'Images';
export const imageAbbr = 'Image';
export const rankingName = 'Évolution du positionnement';
export const rankingLabel = 'ranking';
export const benchmarkName = 'Benchmark concurrentiel';
export const benchmarkLabel = 'benchmark';
export const seoName = 'Suggestions SEO';
export const seoLabel = 'seo';
export const ctrName = 'Taux de clics';
export const ctrLabel = 'ctr';
export const keywordsName = 'Mots clés';
export const resultPhysionomyName = 'Physionomie des SERP';
export const keywordsLabel = 'keywords';

export const serpAnalysisDetailsRouting = [
  {
    label: '',
    pageName: organicName,
    abbr: '',
    type: RANKING_TYPE.ORGANIC,
    exportLabel: organicLabel,
  },
  {
    label: featuredSnippetLabel,
    pageName: featuredSnippetName,
    abbr: featuredSnippetAbbr,
    type: RANKING_TYPE.FEATURED_SNIPPET,
    exportLabel: featuredSnippetLabel,
  },
  {
    label: peopleAlsoAskLabel,
    pageName: peopleAlsoAskName,
    abbr: peopleAlsoAskAbbr,
    type: RANKING_TYPE.PEOPLE_ALSO_ASK,
    exportLabel: peopleAlsoAskLabel,
  },
  {
    label: localPackLabel,
    pageName: localPackName,
    abbr: localPackAbbr,
    type: RANKING_TYPE.LOCAL_PACK,
    exportLabel: localPackLabel,
  },
  {
    label: videoLabel,
    pageName: videoName,
    abbr: videoAbbr,
    type: RANKING_TYPE.VIDEO,
    exportLabel: videoLabel,
  },
  {
    label: imageLabel,
    pageName: imageName,
    abbr: imageAbbr,
    type: RANKING_TYPE.IMAGES,
    exportLabel: imageLabel,
  },
];
