import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Stack } from '@mui/material';
import { useFormik } from 'formik';

import { Analysis } from 'features/serpAnalysis/types';
import serializeSearchParams from 'utils/querySerializer';

import { initFormHistory } from './config';
import PhysionomyInformations from './PhysionomyInformations';
import { PhysionomyHistoryFiltersForm } from '../types';
import PhysionomyHistoryFilters from './PhysionomyHistoryFilters';

type PhysionomyHistoryFiltersContainerProps = {
  isLoading: boolean,
  serpAnalysis?: Analysis,
  disabledInformations?: boolean,
  onSubmit: (filters: PhysionomyHistoryFiltersForm) => void,
  onFiltersChanged?: (filters: PhysionomyHistoryFiltersForm) => void,
}

function PhysionomyHistoryFiltersContainer(props: PhysionomyHistoryFiltersContainerProps) {
  const {
    serpAnalysis,
    isLoading,
    disabledInformations,
    onFiltersChanged,
    onSubmit,
  } = props;
  const [searchParams, setSearchParams] = useSearchParams();

  const deviceId = Number(searchParams.get('deviceId')) || undefined;
  const rankLimit = Number(searchParams.get('rankLimit')) || undefined;
  const periode = searchParams.get('periode') || undefined;
  const showValues = searchParams.get('showValues')
    ? searchParams.get('showValues') === 'true'
    : undefined;
  const showPercentage = searchParams.get('showPercentage')
    ? searchParams.get('showPercentage') === 'true'
    : undefined;
  const hideOrganic = searchParams.get('hideOrganic')
    ? searchParams.get('hideOrganic') === 'true'
    : undefined;

  const handleSubmit = (values: PhysionomyHistoryFiltersForm) => {
    onSubmit(values);
    setSearchParams(serializeSearchParams(values));
  };

  const formik = useFormik<PhysionomyHistoryFiltersForm>({
    initialValues: initFormHistory(
      deviceId ?? serpAnalysis?.device_type_id,
      rankLimit,
      periode,
      showValues,
      showPercentage,
      hideOrganic,
    ),
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    onFiltersChanged?.(formik.values);
  }, [formik.values]);

  return (
    <Stack direction="column" spacing={2}>
      <PhysionomyHistoryFilters
        values={formik.values}
        onChange={formik.handleChange}
        isLoading={isLoading}
        serpAnalysisId={serpAnalysis?.id ?? 0}
        onSubmit={formik.handleSubmit}
        onPeriodsLoaded={(p) => formik.setFieldValue('periode', p)}
      />
      <PhysionomyInformations
        values={formik.values}
        onChange={formik.handleChange}
        onFieldValueUpdate={formik.setFieldValue}
        onBlur={formik.handleBlur}
        disabled={disabledInformations}
      />
    </Stack>
  );
}

export default PhysionomyHistoryFiltersContainer;
